import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "nft-card-back-mmbs" }
const _hoisted_2 = { class: "text-area" }
const _hoisted_3 = { class: "sub-text" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "main-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mv_progress_bar = _resolveComponent("mv-progress-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_mv_progress_bar, {
      data: {
        start: 0,
        end: _ctx.info.total,
        current: _ctx.info.current,
      }
    }, null, 8, ["data"]), [
      [_vShow, _ctx.info.total]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.data.attributes?.startDate)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.info.startDate) + " ~", 1))
          : _createCommentVNode("", true),
        (_ctx.data.attributes?.endDate)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.info.endDate), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.info.location), 1)
    ])
  ]))
}