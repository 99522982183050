import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/event',
    name: 'EventAppView',
    component: () => import('@/components/pages/event_app/DiscoverView.vue'),
    meta: {
      layout: 'event',
      unAuth: true,
    }
  },
  {
    path: '/event/search',
    name: 'EventAppSearchView',
    component: () => import('@/components/pages/event_app/search/DiscoverSearchView.vue'),
    meta: {
      layout: 'blank',
      unAuth: true,
    }
  },
  {
    path: '/event/detail/:id',
    name: 'EventAppDetailView',
    component: () => import('@/components/pages/event_app/DiscoverEventDetailView.vue'),
    meta: {
      layout: 'blank',
      unAuth: true,
      back: { name: 'EventAppView' }
    }
  },
  {
    path: '/event/pay/:id',
    name: 'EventAppPaymentView',
    component: () => import('@/components/pages/event_app/PaymentView.vue'),
    meta: {
      layout: 'blank',
      unAuth: true,
    }
  },
]

export default routes
