import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nft-ntfc-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_receive_nft_comp = _resolveComponent("receive-nft-comp")!
  const _component_UserInviteComp = _resolveComponent("UserInviteComp")!
  const _component_SaleCpltComp = _resolveComponent("SaleCpltComp")!
  const _component_bottom_slide_sheet = _resolveComponent("bottom-slide-sheet")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bottom_slide_sheet, {
      visible: _ctx.isOpen,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpen) = $event)),
      title: "receiveNtfc.title",
      onCancel: _ctx.onOk
    }, {
      content: _withCtx(() => [
        (_ctx.info.type === 'NFT_RECEIVE')
          ? (_openBlock(), _createBlock(_component_receive_nft_comp, {
              key: 0,
              info: (_ctx.info as NtfcNftReceiveResponse),
              onOk: _ctx.onOk
            }, null, 8, ["info", "onOk"]))
          : (_ctx.info.type === 'USER_INVITE')
            ? (_openBlock(), _createBlock(_component_UserInviteComp, {
                key: 1,
                info: (_ctx.info as NtfcUserInviteResponse),
                onOk: _ctx.onOk
              }, null, 8, ["info", "onOk"]))
            : (_ctx.info.type === 'NFT_SELL')
              ? (_openBlock(), _createBlock(_component_SaleCpltComp, {
                  key: 2,
                  info: _ctx.info,
                  onOk: _ctx.onOk
                }, null, 8, ["info", "onOk"]))
              : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "onCancel"])
  ]))
}