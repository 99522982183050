import { Module } from 'vuex'
import { RootState } from '../index'
import { LoginEmailResponse } from '@/models/response/authResponseModel';
export interface AuthState {
    id: string;
    walletId: string;
    accountId: string;
    email: string;
    name: string;
    useAuth: boolean;
    language: string;
    isPhone: boolean;
    customerKey: string;
}

export const authModule: Module<AuthState, RootState> = {
    namespaced: true,
    state: () => ({
        id: '',
        walletId: '',
        accountId: '',
        email: '',
        name: '',
        useAuth: false,
        language: '',
        isPhone: false,
        customerKey: '',
    }),
    mutations: {
        FETCH_AUTH: (state, payload: LoginEmailResponse) => {
            state.id = payload.id
            state.walletId = payload.walletId
            state.accountId = payload.accountId
            state.email = payload.email
            state.name = payload.name
            state.useAuth = payload.useAuth
            state.language = payload.language
            state.isPhone = payload.isPhone
            state.customerKey = payload.customerKey
        },
        INIT_AUTH: (state) => {
            state.id = ''
            state.walletId = ''
            state.accountId = ''
            state.email = ''
            state.name = ''
            state.useAuth = false
            state.language = ''
            state.isPhone = false
            state.customerKey = ''
        }
    },
    actions: {
    },
    getters: {},
}
