import { Module } from 'vuex'
import {RootState} from '../index'
import {NftCtgyFilter, NftSortFilter} from '@/models/types';
export interface StatusState {
  nftList: {
    mode: 'able' | 'disabled'
    type: 'nft' | 'cltn'
    ctgy: NftCtgyFilter,
    sort: NftSortFilter,
    isActive: boolean | '',
    refreshWait: boolean,
  };
  cltnList: {
    ctgy: NftCtgyFilter,
    sort: NftSortFilter,
  },
  couponList: {
    mode: 'able' | 'disabled'
  },
  discoverCltnList: {
    type: NftCtgyFilter,
    sort: NftSortFilter,
  },
  lang: string,
  loading: boolean,
}

export const statusModule: Module<StatusState, RootState> = {
  namespaced: true,
  state: () => ({
    nftList: {
      mode: 'able',
      type: 'nft',
      ctgy: '',
      sort: 'NEWEST',
      isActive: '',
      refreshWait: false,
    },
    cltnList: {
      ctgy: '',
      sort: 'NEWEST',
    },
    couponList: {
      mode: 'able'
    },
    discoverCltnList: {
      type: '',
      sort: 'NEWEST',
    },
    lang: '',
    loading: false,
  }),
  mutations: {
    TOGGLE_LIST_TYPE: (state, payload) => {
      const type = state.nftList.type
      if (payload) state.nftList.type = payload
      else {
        state.nftList.type = type === 'nft' ? 'cltn' : 'nft'
      }
      if (state.nftList.type === 'cltn') {
        state.nftList.mode = 'able'
        state.cltnList.ctgy = ''
        state.cltnList.sort = 'NEWEST'
      } else if (state.nftList.type === 'nft') {
        state.nftList.mode = 'able'
        state.nftList.ctgy = ''
        state.nftList.sort = 'NEWEST'
      }
    },
    FETCH_COUPON_LIST_STATUS: (state, payload) => {
      state.couponList.mode = payload ? 'able' : 'disabled'
    },
    INIT_CLTN_FILTER: (state) => {
      state.cltnList.ctgy = ''
      state.cltnList.sort = 'NEWEST'
    }
  },
  actions: {
    toggleCouponList: ({commit}, payload) => {
      commit('FETCH_COUPON_LIST_STATUS', payload)
    }
  },
  getters: {
    getNftListClass: (state) => {
      switch (state.nftList.mode) {
        case 'able':
          return 'status-able'
        case 'disabled':
          return 'status-disabled'
      }
      return 'able'
    },
    getCouponListClass: (state) => {
      switch (state.couponList.mode) {
        case 'able':
          return 'status-able'
        case 'disabled':
          return 'status-disabled'
      }
      return 'able'
    },
    getLoading: (state) => {
      return state.loading
    }
  },
}
