import { modifyUserProfileImgApi } from '@/api/userApis';

export const getProfileSrc = (src: string) => {
  return src ? src : require('@/assets/images/pictures/profile_default.png')
}

export const useProfileImageEdit = () => {

  const inputImage = async (image: File | any) => {
    const formData = new FormData()
    formData.set('image', image)
    await modifyUserProfileImgApi(formData)
  }

  return {
    inputImage,
  }
}
