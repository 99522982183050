
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    fill: {
      default: "currentColor",
    },
    disabled: {
      default: false,
    },
  },
})
