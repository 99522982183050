
import {defineComponent} from 'vue';

export default defineComponent({
  props: {
    type: {
      default: 'MEMBERSHIP',
    },
    color: {
      default: 'pink',
    }
  }
})
