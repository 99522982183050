import {computed, ref} from 'vue';
import {getNftQtnrTemplateApi} from '@/api/nftApis';
import {QuItem, QuTemplateItem} from '@/models/commonModels';
import {QTNR_SEP} from '@/config/constants';
import {useI18n} from 'vue-i18n';
import {object, string, mixed} from 'yup';
import {getFieldSchema} from '@/script/common/validScript';
import {isValidNum} from '@/utils/phoneNumUtils';

export const parseQtnr = (name: string, t?: any) => {
  const nameArr = name.split(QTNR_SEP)
  return {
    key: nameArr[1] ? nameArr[1] : nameArr[0],
    name: nameArr[1] && t ? t(`qtnrInput.default.${nameArr[1]}`) : nameArr[0],
    plhd: nameArr[1] && t ? t(`qtnrInput.plhd.${nameArr[1]}`) : '',
  }
}

export const useNftQtnrTemplate = () => {
  const form = ref<any>({})
  const { t } = useI18n()
  const qtnr = ref<{ id: number; qtnrTemplate: Array<QuTemplateItem>}>({
    id: 0,
    qtnrTemplate: []
  })
  const pareseQtnrTemplate = (template: any[]) => {
    return template.map((q: QuItem) => {
      const mappedValue = {
        ...parseQtnr(q.name, t),
        keyName: q.name,
        required: q.required,
      }
      form[mappedValue.keyName] = ""
      return mappedValue
    })
  }
  const fetchQtnr = async (nftId: number) => {
    const res = await getNftQtnrTemplateApi(nftId)
    if (res?.qtnrTemplate.length) {
      qtnr.value.qtnrTemplate = pareseQtnrTemplate(res?.qtnrTemplate)
    }
  }
  const phoneRef = ref<any>()
  const validatePhone = (value: any) => {
    if (phoneRef.value) {
      return phoneRef.value[0].validate()
    } else {
      return isValidNum(value)
    }
  }
  const schema = computed(() => {
    const schemaShape: any = {}
    const qTemplate = qtnr.value.qtnrTemplate
    for (const qtItem of qTemplate) {
      if (qtItem.required) {
        if (qtItem.key === 'email') schemaShape[qtItem.keyName] = getFieldSchema(qtItem.key)
        // else if (qtItem.key === 'phone') schemaShape[qtItem.keyName] = string().required('valid.required').matches(RegExp('^01[016789]\\d{3,4}\\d{4}$'), 'valid.phoneFormat')
        else if (qtItem.key === 'phone') schemaShape[qtItem.keyName] = mixed()
          .test('requiredValid', 'valid.phoneFormat', validatePhone)
        else schemaShape[qtItem.keyName] = string().required('valid.required').max(20, (msg) => t('valid.maxLength', { max: msg.max }))
      }
    }
    return object().shape(schemaShape)
  })

  const validate = async (target: any) => {
    await schema.value?.validate(target);
  }

  return {
    qtnr,
    fetchQtnr,
    pareseQtnrTemplate,
    schema,
    form,
    validate,
    phoneRef,
  }
}

export const isQtnrCplt = (qtnrInfo: any, qtnrTemplate: Array<QuItem>) => {
  if (qtnrTemplate.length) return Object.keys(qtnrInfo).length
  else return true
}
