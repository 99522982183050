
import {computed, defineComponent} from 'vue';
import HomeLayoutHeader from '@/components/layout/home_layout/HomeLayoutHeader.vue';
import HomeLayoutFooter from '@/components/layout/home_layout/HomeLayoutFooter.vue';
import NftTypeFilterComp from '@/components/pages/home/comp/NftTypeFilterComp.vue';
import NftSortFilterComp from '@/components/pages/home/comp/NftSortFilterComp.vue';
import CltnTypeFilterComp from '@/components/pages/home/comp/CltnTypeFilterComp.vue';
import CltnSortFilterComp from '@/components/pages/home/comp/CltnSortFilterComp.vue';
import CommonFooter from '@/components/layout/CommonFooter.vue';
import {store} from '@/store';
import {useRoute} from 'vue-router';
import {useHomeListConfig, useHomeListCtgyConfig} from '@/script/homeScript';

export default defineComponent({
  name: 'HomeLayout.vue',
  components: {
    HomeLayoutHeader,
    HomeLayoutFooter,
    NftTypeFilterComp,
    NftSortFilterComp,
    CltnTypeFilterComp,
    CltnSortFilterComp,
    CommonFooter,
  },
  setup() {
    const route = useRoute()
    const modeClass = computed(() => {
      if (route.name === 'HomeView') return store.getters['status/getNftListClass']
      return 'status-able'
    })
    const {
      viewMode,
    } = useHomeListConfig()

    const {
      changeCtgy,
      nftCtgy,
    } = useHomeListCtgyConfig()
    return {
      modeClass,
      viewMode,
      nftCtgy,
      changeCtgy,
    }

  }
})
