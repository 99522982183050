export const NFT_CTGY = {
  MEMBERSHIP: {
    value: 'MEMBERSHIP',
    label: 'Member',
    short: 'MBERSHIP',
    tag: 'nftCard.tag.mmbs',
    i18n: 'nftType.MEMBERSHIP',
    i18nCount: 'nftType.mmbsCount',
  },
  TICKET: {
    value: 'TICKET',
    label: 'Ticket',
    short: 'TICKET',
    tag: 'nftCard.tag.ticket',
    i18n: 'nftType.TICKET',
    i18nCount: 'nftType.ticketCount',
  }
}

export type NftCtgy = keyof typeof NFT_CTGY
export type NftCtgyFilter = NftCtgy | ''
export type NftSortFilter = 'NEWEST' | 'OLDEST'
export type NftMenu = 'sell' | 'send'

export const HISTORY_TYPE = {
  MINTED: {
    value: 'MINTED',
    icon: require('@/assets/images/icons/history/circle-plus.svg'),
    bgColor: '#CCF3EE'
  },
  SEND: {
    value: 'SEND',
    icon: require('@/assets/images/icons/history/send.svg'),
    bgColor: '#CCF3EE'
  },
  SEND_FAIL: {
    value: 'SEND_FAIL',
    icon: require('@/assets/images/icons/history/send_fail.svg'),
    bgColor: '#FFE3E3'
  },
  USE: {
    value: 'USE',
    icon: {
      mmbs: require('@/assets/images/icons/history/member.svg'),
      ticket: require('@/assets/images/icons/history/ticket.svg'),
      pay: require('@/assets/images/icons/history/pay.svg'),
      coupon: require('@/assets/images/icons/history/coupon_percent.svg')
    },
    bgColor: '#CCF5FA'
  },
  USE_CANCEL: {
    value: 'USE_CANCEL',
    icon: require('@/assets/images/icons/history/pay_cancel.svg'),
    bgColor: '#FFE3E3'
  },
  USE_FAIL: {
    value: 'USE_FAIL',
    bgColor: '#FFE3E3',
    icon: require('@/assets/images/icons/history/use_fail.svg')
  },
  EXPIRED: {
    value: 'EXPIRED',
    bgColor: '#F6F6F7',
    icon: {
      mmbs: require('@/assets/images/icons/history/member_expired.svg'),
      ticket: require('@/assets/images/icons/history/ticket_expired.svg'),
      coupon: require('@/assets/images/icons/history/coupon_expired.svg')
    },
  },
  SELL: {
    value: 'SELL',
    icon: require('@/assets/images/icons/history/send.svg'),
    bgColor: '#CCF3EE'
  },
  FIRST_BUY: {
    value: 'FIRST_BUY',
    icon: require('@/assets/images/icons/history/send.svg'),
    bgColor: '#CCF3EE'
  }
}

export const COUPON_STATUS = {
  ACTIVE: {
    value: "ACTIVE",
    label: "couponStatus.active",
    color: "secondary",
  },
  UNUSE: {
    value: "UNUSE",
    label: "couponStatus.unuse",
    color: "secondary",
  },
  USED: {
    value: "USED",
    label: "couponStatus.used",
    color: "secondary",
  },
  EXPIRED: {
    value: "EXPIRED",
    label: "couponStatus.expired",
    color: "secondary",
  },
  DEACTIVE: {
    value: "DEACTIVE",
    label: "couponStatus.deactive",
    color: "secondary",
  },
  default: {
    value: "",
    label: "",
    color: "",
  }
};

export type CouponStatus = keyof typeof COUPON_STATUS

export const CURRENCY_TYPE = {
  USD: {
    value: 'USD',
    label: 'USD - $',
    sign: '$',
    i18n: 'currency.usd',
    i18nUnit: 'currency.usdUnit',
  },
  EUR: {
    value: 'EUR',
    label: 'EUR - €',
    sign: '€',
    i18n: 'currency.eur',
    i18nUnit: 'currency.eurUnit'
  },
  KRW: {
    value: 'KRW',
    label: 'KRW - ￦',
    sign: '￦',
    i18n: 'currency.krw',
    i18nUnit: 'currency.krwUnit',
  },
}
export type CurrencyType = keyof typeof CURRENCY_TYPE

export type HistoryType = keyof typeof HISTORY_TYPE

export const SORT_TYPE = {
  NEWEST: {
    value: 'NEWEST',
    label: 'listFilter.new',
  },
  OLDEST: {
    value: 'OLDEST',
    label: 'listFilter.old',
  }
}

export type SendMethod = 'qr' | 'email'

export type HomeViewType = 'nft' | 'cltn'

export const ORDER_TYPE = {
  BUY: {
    value: 'NEWEST',
  },
  SELL: {
    value: 'OLDEST',
  }
}

export type OrderType = keyof typeof ORDER_TYPE
