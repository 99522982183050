
import {computed, defineComponent} from 'vue';
import MvButton from '@/components/common/button/MvButton.vue';
import NftCardComp from '@/components/common/card/NftCardComp.vue';
import { getProfileSrc } from '@/script/profile/profileImageScript';

export default defineComponent({
  name: 'SendNftConfirmComp.vue',
  emits: ['ok'],
  props: {
    info: {
      default: {
        id: 0,
        type: '',
        cltnType: '',
        color: '',
        logoImg: '',
        image: '',
        brandName: '',
        brandProfile: '',
        name: '',
        nftType: '',
        sender: {
          id: 0,
          name: '',
          profileImg: '',
        },
      },
    },
  },
  components: {
    MvButton,
    NftCardComp,
  },
  setup(props) {
    const cardInfo = computed(() => {
      return {
        ...props.info,
        type: props.info.nftType
      }
    })
    return {
      getProfileSrc,
      cardInfo,
    };
  },
});
