
import { computed, defineComponent } from "vue";
import MvProgressBar from "@/components/common/display/MvProgressBar.vue";
import {getLocationName} from '@/utils/stringUtil';
import {formatDate} from '@/models/utils/dateUtils';

export default defineComponent({
  name: "MmbsCardBack.vue",
  components: {
    MvProgressBar,
  },
  props: {
    data: {
      default: {
        nftUseStatus: {
          useableCnt: 0,
          totalCnt: 0,
        },
        attributes: {
          endDate: "",
          location: "",
          startDate: "",
          timeZone: "",
        },
      },
    },
  },
  setup(props) {
    const info = computed(() => {
      return {
        total: props.data.nftUseStatus?.totalCnt,
        current: props.data.nftUseStatus?.useableCnt,
        endDate: formatDate(props.data.attributes.endDate, {
          format: 'L',
          timeZone: props.data.attributes.timeZone,
        }),
        location: getLocationName(props.data.attributes.location),
        startDate: formatDate(props.data.attributes.startDate, {
          format: 'L',
          timeZone: props.data.attributes.timeZone,
        }),
      };
    });

    return {
      info,
    };
  },
});
