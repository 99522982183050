
import {defineComponent, computed, onMounted, watch} from "vue";
import BlankLayout from "@/components/layout/BlankLayout.vue";
import HomeLayout from "@/components/layout/HomeLayout.vue";
import DiscoverLayout from '@/components/layout/DiscoverLayout.vue';
import NtfcView from "@/components/pages/popup/NtfcView.vue";
import NftNtfcView from "@/components/pages/popup/NftNtfcView.vue";
import SearchLayout from "./components/layout/SearchLayout.vue";
import EventLayout from '@/components/layout/EventLayout.vue';
import AlertPopupView from '@/components/pages/popup/AlertPopupView.vue';
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import { store } from "./store";
import {LANG, WHITE_LABEL} from '@/config/constants';
import LoadingView from '@/components/pages/LoadingView.vue';
import {useAppLoading} from '@/script/common/loadingScript';

export default defineComponent({
  name: "App",
  components: {
    HomeLayout,
    BlankLayout,
    NtfcView,
    NftNtfcView,
    SearchLayout,
    DiscoverLayout,
    EventLayout,
    AlertPopupView,
    LoadingView,
  },
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      switch (route.meta.layout) {
        case "blank":
          return "blank-layout";
        case "search":
          return "search-layout";
        case "list":
          return "home-layout";
        case "discover":
          return 'discover-layout';
        case "event":
          return 'event-layout';
        default:
          return "blank-layout";
      }
    });

    const { locale } = useI18n();
    if (route.query.lang) {
      locale.value = route.query.lang as string;
    }


    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    // resize
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    watch(() => store.state.status.lang, (next) => {
      if (next) {
        locale.value = next
        dayjs.locale(locale.value)
      }
    })

    const {
      appLoading,
    } = useAppLoading()

    const wl = sessionStorage.getItem(WHITE_LABEL)
    if (wl) {
      store.dispatch('label/setLabel', { name: wl })
    }
    const labelName = computed(() => {
      return store.state.label.name
    })
    onMounted(() => {
      if (localStorage.getItem(LANG)) {
        store.state.status.lang = localStorage.getItem(LANG) as string
      }
      else {
        const lang = navigator.language
        if (lang) {
          const localeValue = lang.substring(0, 2)
          store.state.status.lang = localeValue === 'ko' ? 'ko' :'en'
        }
        localStorage.setItem(LANG, store.state.status.lang)
      }
    })

    return {
      layout,
      locale,
      appLoading,
      labelName,
    };
  },
});
