import {computed, reactive} from 'vue';
import {store} from '@/store';
import {NftCtgyFilter, NftSortFilter} from '@/models/types';

export const useDiscoverListTypeConfig = () => {
  const cltnType = computed(() => {
    return store.state.status.discoverCltnList.type;
  });
  const changeType = (value: NftCtgyFilter) => {
    store.state.status.discoverCltnList.type = value;
  };

  return {
    cltnType,
    changeType,
  };
};

export const useCltnListSortConfig = () => {
  const cltnSort = computed(() => {
    return store.state.status.discoverCltnList.sort;
  });
  const changeSort = (value: NftSortFilter) => {
    store.state.status.discoverCltnList.sort = value;
  };

  return {
    cltnSort,
    changeSort,
  };
};

export const useCltnFilter = () => {
  const selected = reactive<{
    sort: NftSortFilter,
    status: boolean,
  }>({
    sort: 'NEWEST',
    status: true
  })
  const sortOption = [
    {
      label: 'listFilter.new',
      value: 'NEWEST',
    },
    {
      label: 'listFilter.old',
      value: 'OLDEST',
    },
  ]
  const sortLabel = computed(() => {
    const sort = sortOption.find(op => op.value === selected.sort)
    if (sort) return sort.label
    return ''
  })

  return {
    sortOption,
    selected,
    sortLabel,
  }
}
