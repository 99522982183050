const ERROR_GROUP = {
  'M-W-1013': { message: 'valid.invalidPw' },
  'M-W-1202': { message: 'ntfc.incorrectPasscode'},
  'M-W-1200': { message: 'ntfc.expiredPasscode' },
  '1000': { message: 'valid.codeRequired' },
  '1023': { message: 'valid.invalidPw' },
  '1003': { message: 'ntfc.invalidEvent'},
  '2001': { message: 'ntfc.existEventNft'},
  '2004': { message: 'valid.notUser' },
  '2509': { message: 'valid.expiredRequest'},
  '2510': { message: 'valid.alreadyInvited'},
  '3001': {message: 'ntfc.incorrectPasscode'},
  '3004': {message: 'ntfc.expiredLink'},
  '3101': {message: 'ntfc.invalidPeriod'},
  '3102': { message: 'ntfc.qrMintingLimit'},
  '4501': { message: 'ntfc.issuingLimit'},
  '4504': { message: 'ntfc.issuingLimit'},
  '6500': { message: 'ntfc.alreadyReservedSeat'},
  'M-2001': {message: 'ntfc.existEventNft'},
  '2508': { message: 'ntfc.notVerifiedEmail' },
  'M-W-1100': { message: 'ntfc.authExpired' },
}

type ErrorCode = keyof typeof ERROR_GROUP

export const getCustomError = (errorCode: string | number, data?: any) => {
  if (errorCode) {
    const error = ERROR_GROUP[errorCode.toString() as ErrorCode]
    if (error) return new CustomError({ errorCode: errorCode.toString(), message: error.message, data })
  }
  return false
}

export class CustomError {
  public errorCode = ''
  public message = ''
  #data = {}
  constructor({errorCode, message, data}: {errorCode: string, message: string, data?: any}) {
    this.errorCode = errorCode
    this.message = message
    this.#data = data
  }

  public getError = () => {
    return {
      errorCode: this.errorCode,
      message: this.message,
      data: this.#data
    }
  }
  public isErrorCode = (errorCode: string) => {
    return errorCode === this.errorCode
  }
}

