import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "nft-type-filter-comp" }
const _hoisted_2 = { class: "tab-list" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mv_tab = _resolveComponent("mv-tab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterList, (filter, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "tab-item cursor-pointer",
          onClick: ($event: any) => (_ctx.changeType(filter.value as NftCtgy))
        }, [
          _createVNode(_component_mv_tab, {
            content: filter,
            selected: _ctx.cltnType === filter.value,
            type: "text"
          }, null, 8, ["content", "selected"])
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}