
import { computed, defineComponent } from 'vue'
import dayjs from 'dayjs';
import {useI18n} from 'vue-i18n';
import {CURRENCY_TYPE, CurrencyType} from '@/models/types';

export default defineComponent({
  name: 'MmbsCardBack.vue',
  props: {
    data: {
      default: {
        nftUseStatus: {
          useableCnt: 0,
          totalCnt: 0,
          monthCnt: 0,
        },
        priceUnit: '',
        attributes: {
          endDate: '',
          location: '',
          startDate: '',
        },
      },
    },
  },
  setup(props) {
    const info = computed(() => {
      return {
        monthPrice: props.data?.nftUseStatus?.monthCnt?.toLocaleString()
      }
    })
    const { locale } = useI18n()
    const unit = computed(() => {
      if (props.data.priceUnit) {
        return CURRENCY_TYPE[props.data.priceUnit as CurrencyType].i18n
      }
      return ''
    })
    const month = computed(() => {
      if (locale.value === 'ko') return dayjs().month() + 1
      else {
        dayjs.locale(locale.value)
        return dayjs().format('MMMM')
      }
    })

    return {
      info,
      month,
      unit,
    }
  },
})
