
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { getNftTCtgy } from "@/script/constantScript";
import { NftCtgy } from "@/models/types";
import MmbsCardBack from "@/components/common/card/card_back/MmbsCardBack.vue";
import TicketCardBack from "@/components/common/card/card_back/TicketCardBack.vue";
import PayCardBack from '@/components/common/card/card_back/PayCardBack.vue';
import QrStatusComp from "@/components/common/card/card_back/QrStatusComp.vue";
import NftLineSvg from "@/components/svg/NftLineSvg.vue";
import MvButton from '@/components/common/button/MvButton.vue';
import { dataToUrl } from "@/script/common/qrScript";
import dayjs from "@/utils/dayjs";
import { useGenerateQRData } from "@/script/nft/nftDetailScript";
import { useRoute } from "vue-router";
import BottomSlideSheet from "@/components/common/modal/BottomSlideSheet.vue";
import { useModal } from "@/script/common/modalScript";
import NftCardInfoComp from "../modal/comp/NftCardInfoComp.vue";
import {isQtnrCplt} from '@/script/nft/nftQtnrScript';
import {useI18n} from 'vue-i18n';
import {formatDate} from '@/models/utils/dateUtils';

export default defineComponent({
  name: "NftCardComp",
  components: {
    MmbsCardBack,
    TicketCardBack,
    NftLineSvg,
    QrStatusComp,
    BottomSlideSheet,
    NftCardInfoComp,
    PayCardBack,
    MvButton,
  },
  props: {
    flipLock: {
      default: false,
    },
    disabled: {
      default: "",
    },
    flipAppear: {
      default: false,
    },
    loading: {
      default: false,
    },
    data: {
      default: {
        id: "",
        cltnType: "",
        tokenId: "",
        color: "",
        logoImg: "",
        image: "",
        cltnName: "",
        name: "",
        isExpired: false,
        isLock: false,
        status: "",
        qrData: "",
        type: "",
        openDate: "",
        attributes: {
          startDate: "",
          openDate: "",
          timeZone: "",
        },
        nftUseStatus: {
          totalCnt: 0,
          useableCnt: 0,
        },
        qtnrTemplate: [],
        qtnrInfo: {},
      },
    },
    isPreview: {
      default: false
    },
    qrData: {
      default: "",
    },
    info: {
      default: false,
    },
  },
  setup(props, context) {
    const { qrData, loading: qrLoading, generateQRData } = useGenerateQRData();
    const route = useRoute();
    const { t } = useI18n()
    const nftId = route.params.id;

    const qrImage = ref("");
    const nftData = computed(() => {
      const data = props.data;
      const nftCtgy = getNftTCtgy(data.cltnType as NftCtgy);
      const getName = () => {
        let nftName = data.name
        if (nftName.indexOf('#') === -1 && data.cltnType === 'TICKET') {
          const dateString = data.attributes?.startDate
              ? formatDate(data.attributes.startDate, { format: t('nftCard.nameDateForm'), timeZone: data.attributes.timeZone })
              : ''
          nftName += `_${dateString}` + (data.tokenId ? ` #${data.tokenId}` : '')
        }
        return nftName
      }
      return {
        id: data.id,
        tag: nftCtgy.tag,
        color: data.color?.toLowerCase(),
        logoImg: data.logoImg,
        image: data.image,
        cltnName: data.cltnName,
        name: getName(),
        isExpired: data.isExpired,
        status: props.isPreview ? "ACTIVE" : data.status,
        isQtnr: data.qtnrTemplate ? isQtnrCplt(data.qtnrInfo, data.qtnrTemplate) : true
      };
    });

    const disabledStatus = computed(() => {
      if (props.data) {
        if (props.disabled === "lock" || props.data.isLock) return "lock";
        if (props.disabled === "expired" || props.data.isExpired)
          return "expired";
        if (props.data.status === "USED") return "used";
      }
      return false;
    });

    const className = computed(() => {
      const name = [];
      if (props.loading) return name.push("card-loading");
      if (props.data.isExpired) name.push("nft-expired");
      if (props.data.color) {
        if (props.data.type === 'PAY') name.push('nft-color-pay')
        else name.push(`nft-color-${nftData.value.color}`)
      }
      if (isFlip.value) name.push("nft-card-flipped")
      if (props.data.cltnType === "TICKET") name.push("nft-card-comp-ticket")
      if (props.data.cltnType === "MEMBERSHIP") name.push("nft-card-comp-mmbs")
      if (props.data.type === 'PAY') name.push('nft-card-comp-pay')
      if (disabledStatus.value) {
        name.push("nft-card-disabled")
      }
      return name.join(' ');
    });

    const qrStatus = computed(() => {
      const data = props.data;
      if (disabledStatus.value) return disabledStatus.value;
      if (data.openDate) {
        if (dayjs().isBefore(data.openDate)) {
          return 'beforeOpen'
        }
      } else if (data.attributes?.openDate) {
        if (dayjs().isBefore(data.attributes?.openDate)) {
          return 'beforeOpen'
        }
      } else if (data.cltnType === "TICKET") {
        if (data.attributes.startDate) {
          if (dayjs().isBefore(dayjs(data.attributes.startDate)))
            return "ticketLock";
        }
      }
      return false;
    });

    const centerIcon = computed(() => {
      if (disabledStatus.value === "used") {
        return {
          src: require("@/assets/images/icons/used_mark.png"),
          className: "used-mark",
        };
      }
      if (disabledStatus.value === "lock") {
        return {
          src: require("@/assets/images/icons/lock_gray.png"),
          className: "lock-mark",
        };
      }
      return false;
    });

    let refreshQRData1: any;
    let refreshQRData2: any;
    const isFlip = ref(false);
    const flippCard = async () => {
      if (!props.flipLock) isFlip.value = !isFlip.value;
      if (!isFlip.value) {
        clearInterval(refreshQRData1);
      }
      if (isFlip.value && qrData.value.qrType === "DYNAMIC") {
        await generateQRData(props?.data.id);
        refreshQRData1 = setInterval(async () => {
          await generateQRData(props?.data.id);
        }, qrData.value.qrValidTime * 1000);
      }
    };
    const ticketLock = computed(() => {
      if (props.data.cltnType === "TICKET") {
        return dayjs().isBefore(dayjs(props.data.attributes.startDate));
      }
      return false;
    });

    watch(
      () => qrData.value,
      async (next) => {
        if (next) qrImage.value = await dataToUrl(qrData.value.qrData);
      }
    );
    watch(() => props.data, async (next, prev) => {
      if (next && !prev && isFlip) {
        await generateQRData(props.data.id);
      }
    })
    onMounted(async () => {
      if (props.flipAppear) {
        await generateQRData(props.data.id);
        isFlip.value = false;
        setTimeout(() => {
          isFlip.value = true;
        }, 500);
        if (qrData.value.qrType === "DYNAMIC") {
          refreshQRData2 = setInterval(async () => {
            await generateQRData(props?.data.id);
          }, qrData.value.qrValidTime * 1000);
        }
      }
    });
    onUnmounted(() => {
      clearInterval(refreshQRData1);
      clearInterval(refreshQRData2);
    });
    const { isOpen, toggleModal } = useModal()
    return {
      nftData,
      className,
      flippCard,
      centerIcon,
      disabledStatus,
      qrImage,
      ticketLock,
      qrStatus,
      isOpen,
      toggleModal
    };
  },
});
