import { Module } from "vuex";
import { RootState } from "../index";
import { getNftDetailApi } from "@/api/nftApis";
import router from "@/router";
export interface DetailState {
  nft: any;
}

export const detailModule: Module<DetailState, RootState> = {
  namespaced: true,
  state: () => ({
    nft: {
      id: "",
      cltnLogoImg: "",
      cltnName: "",
      cltnType: "",
      color: "",
      image: "",
      brandImg: "",
      brandName: "",
      name: "",
      type: "",
      attributes: {},
      isExpired: false,
      status: "",
      network: "",
      contractAddress: "",
      walletAddress: "",
      contractUrl: "",
      walletUrl: "",
      nftUseStatus: {
        useableCnt: 0,
        totalCnt: 0,
        monthCnt: 0,
      },
      couponUseStatus: {
        useableCnt: 0,
        totalCnt: 0,
      },
    },
  }),
  mutations: {
    FETCH_NFT_DETAIL: (state, payload) => {
      state.nft = payload;
    },
    INIT_NFT_DETAIL: (state) => {
      state.nft = {
        id: "",
        cltnLogoImg: "",
        cltnName: "",
        cltnType: "",
        color: "",
        image: "",
        brandImg: "",
        brandName: "",
        name: "",
        type: "",
        attributes: {},
        isExpired: false,
        status: "",
        network: "",
        contractAddress: "",
        walletAddress: "",
        contractUrl: "",
        walletUrl: "",
        nftUseStatus: {
          useableCnt: 0,
          totalCnt: 0,
          monthCnt: 0,
        },
        couponUseStatus: {
          useableCnt: 0,
          totalCnt: 0,
        },
      };
    },
  },
  actions: {
    fetchNftDetail: async ({ commit }, payload) => {
      try {
        const detail = await getNftDetailApi(payload);
        commit("FETCH_NFT_DETAIL", detail);
      } catch (e) {
        router.push({ name: "HomeView" });
      }
    },
  },
  getters: {},
};
