import { Module } from 'vuex'
import { RootState } from '../index'
import { PopupItem } from '@/models/popupModels';
export interface PopupState {
    ntfc: PopupItem[]
    alert: PopupItem[]
}

export const popupModule: Module<PopupState, RootState> = {
    namespaced: true,
    state: () => ({
        ntfc: [],
        alert: [],
    }),
    mutations: {
        DISPLAY_NTFC: (state, { text, options }) => {
            const ntfcItem = { text, options }
            state.ntfc = [ntfcItem, ...state.ntfc]
            setTimeout(() => {
                state.ntfc.pop()
            }, 2500)
        },
        DISPLAY_ALERT: (state, { text, options }) => {
            const alertItem = { text, options }
            state.alert.push(alertItem)
        }
    },
    actions: {
    },
    getters: {},
}
