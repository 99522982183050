<script lang="ts" setup>
</script>

<template>
  <div class="mv-logo">
    <svg width="116" height="34" viewBox="0 0 116 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_95_2060)">
        <path d="M102.162 7C99.5109 7.0029 96.9687 8.05732 95.0936 9.93197C93.2184 11.8066 92.1633 14.3485 92.1597 17C92.1597 22.6065 96.6822 27 102.456 27C105.606 27 108.716 25.5593 110.568 23.2404L107.796 21.0258C106.619 22.4995 104.522 23.4517 102.456 23.4517C99.259 23.4517 96.7124 21.5307 95.9467 18.7728H111.57V17C111.57 11.2975 107.525 7 102.162 7ZM95.9549 15.2327C96.3423 13.8849 97.1571 12.6995 98.2767 11.8551C99.3964 11.0107 100.76 10.553 102.162 10.551C104.992 10.551 107.176 12.4391 107.827 15.2327H95.9549Z" fill="#00C4AC"/>
        <path d="M25.826 10.5454C27.0396 10.5469 28.203 11.0296 29.0611 11.8877C29.9192 12.7458 30.402 13.9093 30.4034 15.1228V26.2342H33.9517V15.1228C33.9524 13.4378 33.429 11.7944 32.454 10.4202C31.4789 9.04601 30.1005 8.00919 28.5098 7.45343C26.9191 6.89767 25.195 6.85051 23.5763 7.31848C21.9576 7.78645 20.5246 8.74637 19.4759 10.0652C18.4271 8.74637 16.9941 7.78645 15.3754 7.31848C13.7567 6.85051 12.0326 6.89767 10.4419 7.45343C8.85122 8.00919 7.47282 9.04601 6.49775 10.4202C5.52269 11.7944 4.99926 13.4378 5 15.1228V26.2342H8.54556V15.1228C8.54556 13.9088 9.02781 12.7445 9.88624 11.8861C10.7447 11.0277 11.9089 10.5454 13.1229 10.5454C14.3369 10.5454 15.5012 11.0277 16.3596 11.8861C17.2181 12.7445 17.7003 13.9088 17.7003 15.1228V26.2342H21.2377V15.1228C21.2384 14.521 21.3576 13.9252 21.5886 13.3694C21.8196 12.8137 22.1578 12.3089 22.5838 11.8838C23.0099 11.4588 23.5155 11.1218 24.0718 10.8922C24.6281 10.6625 25.2242 10.5447 25.826 10.5454Z" fill="#00C4AC"/>
        <path d="M51.748 19.6262C51.0973 21.08 49.9314 22.2413 48.4751 22.8864C47.0189 23.5315 45.3753 23.6146 43.8614 23.1196C42.3475 22.6247 41.0704 21.5869 40.2764 20.2062C39.4823 18.8255 39.2276 17.1997 39.5612 15.6423C39.8948 14.0849 40.7932 12.7061 42.0831 11.7718C43.3731 10.8375 44.9632 10.4139 46.547 10.5825C48.1308 10.7512 49.5961 11.5002 50.6603 12.6852C51.7246 13.8702 52.3125 15.4073 52.3106 17C52.3072 19.3554 53.1341 21.6366 54.6461 23.4426C56.1581 25.2486 58.2583 26.4639 60.5775 26.8748C62.8968 27.2856 65.2867 26.8658 67.327 25.6891C69.3674 24.5124 70.9277 22.6541 71.7336 20.4409C72.5395 18.2277 72.5395 15.8012 71.7336 13.588C70.9276 11.3748 69.3673 9.5166 67.3269 8.33993C65.2864 7.16326 62.8966 6.74349 60.5773 7.15442C58.2581 7.56534 56.1579 8.78065 54.646 10.5867C55.4765 11.7214 56.0786 13.0066 56.4187 14.371C57.0694 12.9174 58.2352 11.7561 59.6913 11.111C61.1475 10.4659 62.7909 10.3827 64.3047 10.8774C65.8186 11.3721 67.0957 12.4097 67.8899 13.7901C68.6841 15.1706 68.9391 16.7962 68.6059 18.3536C68.2726 19.911 67.3747 21.2898 66.0851 22.2244C64.7955 23.159 63.2056 23.583 61.6218 23.4149C60.0381 23.2468 58.5727 22.4983 57.5081 21.3138C56.4434 20.1293 55.855 18.5926 55.8562 17C55.8596 14.6446 55.0326 12.3634 53.5207 10.5574C52.0087 8.75136 49.9085 7.5361 47.5892 7.12523C45.27 6.71436 42.8801 7.13418 40.8397 8.31089C38.7994 9.48761 37.2391 11.3459 36.4332 13.5591C35.6272 15.7723 35.6273 18.1988 36.4332 20.412C37.2392 22.6251 38.7995 24.4834 40.8399 25.6601C42.8803 26.8367 45.2702 27.2565 47.5895 26.8456C49.9087 26.4347 52.0089 25.2193 53.5208 23.4133C52.6907 22.2773 52.0887 20.9913 51.748 19.6262Z" fill="#00C4AC"/>
        <path d="M93.6635 7.76562H89.635L82.4149 21.174L75.1976 7.76562H71.1827L81.202 26.377H83.6718L93.6635 7.76562Z" fill="#00C4AC"/>
      </g>
      <defs>
        <clipPath id="clip0_95_2060">
          <rect width="106.572" height="20" fill="white" transform="translate(5 7)"/>
        </clipPath>
      </defs>
    </svg>

  </div>
</template>

<style lang="scss" scoped>
.mv-logo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
</style>

