import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/pay/result/:status',
    name: 'PayResultView',
    component: () => import('@/components/pages/payment/PaymentResultView.vue'),
    meta: {
      layout: 'blank',
      unAuth: true,
    }
  },
];

export default routes
