
import { computed, defineComponent } from "vue";
import MvNtfc from "@/components/common/display/MvNtfc.vue";
import { store } from "@/store";

export default defineComponent({
  name: "NtfcView",
  components: {
    MvNtfc,
  },
  setup() {
    const ntfcList = computed(() => {
      return store.state.popup.ntfc;
    });

    return {
      ntfcList,
    };
  },
});
