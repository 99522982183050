
import {computed, defineComponent} from "vue";

export default defineComponent({
  name: "MvButton",
  props: {
    i18n: {
      default: true
    },
    text: {
      default: ''
    },
    type: {
      default: 'round-primary',
    },
    color: {
      default: ''
    },
    htmlType: {
      default: 'button'
    },
    disabled: {
      default: false
    },
    loading: {
      default: false
    },
  },
  setup(props) {
    const typeClass = computed(() => {
      switch (props.type) {
        case 'round-black': return 'round-black-type'
        case 'round-white': return 'round-white-type'
        case 'round-primary': return 'round-primary-type'
        case 'rec': return 'rec-type'
        case 'rec-primary': return 'rec-primary-type'
        case 'rec-secondary': return 'rec-secondary-type'
        case 'rec-red': return 'rec-red-type'
        case 'toner-primary': return 'toner-primary'
        case 'toner-red': return 'toner-red'
        default: return 'round-type'
      }
    })
    const btnClass = computed(() => {
      const className = []
      if (props.disabled || props.loading) className.push('mv-button-disabled')
      return className.join(' ')
    })

    return {
      typeClass,
      btnClass,
    }
  }
})
