export const REMEMBER_ID = 'rememberId'
export const LAST_ORDER_ID  = 'lastEventId'
export const PRICE_MAX = 2100000000
export const LANG = 'lang'
export const HISTORY_FILTER = ['MINTED', 'SEND', 'USE', 'USE', 'USE_CANCEL', 'USE_FAIL', 'EXPIRED', 'SELL', 'FIRST_BUY']
export const EVENT_APP_URL = process.env.VUE_APP_EVENT_APP_URL
export const WALLET_URL = process.env.VUE_APP_WALLET_URL
export const ROUTE_STORAGE = 'route'
export const ERR_ROUTE_STORAGE = 'errRoute'
export const PASS_STORAGE = 'pass'
export const INIT_LOGIN = 'init_login'
export const Q_STORAGE = 'q'
export const EXT_STORAGE = 'ext'
export const DATE_FORMAT = 'lll'
export const QTNR_SEP = ':'
export const INFO_EMAIL = 'info@moove.live'
export const INPUT_LIMIT = 20
export const PHONE_LENGTH = 20
export const TOSS_PAYMENTS_KEY = process.env.VUE_APP_TOSS_PAYMENTS_KEY
export const MINI_MAP_WIDTH = 82
export const HID_LINK = 'hidl'
export const WHITE_LABEL = 'wl'
export const STORAGE_LIST = ['ext', 'hidl', 'q', 'route', 'pass']
