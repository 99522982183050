import apiCaller from "./index";
import {InitEmailPwdRequest, LogInEmailRequest, SignupEmailRequest} from '@/models/request/authRequestModel';
import {LoginEmailResponse} from '@/models/response/authResponseModel';
import {GetProfileInfoResponse} from '@/models/response/profileResponseModel';
import {
  ModifyProfileInfoRequest,
  ModifyUserPwdRequest
} from '@/models/request/userRequestModel';

export async function signupEmailApi(request: SignupEmailRequest) {
  const response = await apiCaller.post("/users/signup/email",
    {body: request,}, {});
  return response.data;
}

export async function resendVerifyEmailApi(email: string) {
  const response = await apiCaller.post("/users/resend/email/verify",
    {body: { email },}, {});
  return response.data;
}

export async function checkUserApi() {
  const response = await apiCaller.get("/users/check",
    {}, {});
  return response.data;
}

export async function modifyTermsCheckApi(request: {allowAd: boolean}) {
  const response = await apiCaller.put("/users/terms_check",
    {
      body: request
    }, {});
  return response.data;
}

export async function modifyIsFirstApi() {
  const response = await apiCaller.put("/users/first", {}, {});
  return response.data;
}

export async function logoutApi() {
  const response = await apiCaller.post("/users/signout",
    {}, {});
  return response.data;
}

export async function loginEmailApi(request: LogInEmailRequest): Promise<LoginEmailResponse> {
  const response = await apiCaller.post("/users/signin/email",
    {body: request,}, {});
  return response.data;
}

export async function sendInitPwdEmailApi(email: string, wl: string | null) {
  const response = await apiCaller.post("/users/reset/pwd/email",
    { body: { email }, query: { wl } }, {});
  return response.data;
}

export async function initEmailPwdApi(request: InitEmailPwdRequest) {
  const response = await apiCaller.put("/users/reset/pwd",
    { body: request }, {});
  return response.data;
}


export async function getProfileInfoApi(): Promise<GetProfileInfoResponse> {
  const response = await apiCaller.get("/users/me",
    {}, {});
  return response.data;
}

export async function modifyProfileInfoApi(request: ModifyProfileInfoRequest) {
  const response = await apiCaller.put("/users/me",
    { body: request }, {});
  return response.data;
}

export async function modifyUserPwdApi(request: ModifyUserPwdRequest) {
  const response = await apiCaller.put("/users/pwd",
    { body: request }, {});
  return response.data;
}

export async function modifyUserProfileImgApi(request: FormData) {
  const response = await apiCaller.put("/users/profile-img",
    { body: request }, {});
  return response.data;
}

export async function inviteUserApi(request: { email: string }) {
  const response = await apiCaller.post("/users/invite",
    { body: request }, {});
  return response.data;
}

export async function RegisterPhoneApi(request: { phone: string }) {
  const response = await apiCaller.put("/users/phone",
    { body: request }, {});
  return response.data;
}

export async function linkExternalUserApi(request: { userExtId: string }) {
  const response = await apiCaller.put("/users/external/link",
    { body: request }, {});
  return response.data;
}


