import { Module } from 'vuex'
import { RootState } from '../index'
export interface moduleAState {
    count: number;
}

export const moduleA: Module<moduleAState, RootState> = {
    namespaced: true,
    state: () => ({
        count: 0,
    }),
    mutations: {
        INCREMENTS: (state) => {
            state.count++;
        }
    },
    actions: {
        increment: ({commit}) => {
            commit('INCREMENTS')
        },
    },
    getters: {},
}
