
import { computed, defineComponent } from "vue";
import HomeLayoutFooter from "@/components/layout/home_layout/HomeLayoutFooter.vue";
import { store } from "@/store";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "HomeLayout.vue",
  components: {
    HomeLayoutFooter,
  },
  setup() {
    const route = useRoute();

    return {};
  },
});
