
import {defineComponent} from 'vue';
import Mmbs from '@/components/svg/MmbsSvg.vue';
import Ticket from '@/components/svg/TicketSvg.vue';

export default defineComponent({
  name: 'FilterSvgGroup',
  components: {
    Mmbs,
    Ticket
  },
  props: {
    icon: {
      default: ''
    }
  }
})
