
import {computed, defineComponent} from "vue";
import HomeLayoutFooter from '@/components/layout/home_layout/HomeLayoutFooter.vue';
import CommonFooter from '@/components/layout/CommonFooter.vue';
import CommonHeader from '@/components/layout/CommonHeader.vue';
import {useRoute} from 'vue-router';
import {store} from '@/store';
import {useCouponListModeConfig} from '@/script/common/couponScript';

export default defineComponent({
  name: "CommonLayout",
  components: {
    HomeLayoutFooter,
    CommonFooter,
    CommonHeader,
  },
  setup() {
    const route = useRoute()
    const {
      className: couponClass,
    } = useCouponListModeConfig()
    const className = computed(() => {
      let className = ''
      if (route.name === 'CouponListView') className += couponClass.value
      if (route.meta?.blankTheme === 4) className += ' blank-layout-theme-4'
      return className
    })

    const theme3Top = () => {
      return window.innerHeight - 140
    }

    return {
      className,
      theme3Top,
    }
  }
});
