
import {computed, defineComponent, ref} from "vue";
import {CURRENCY_TYPE, CurrencyType} from '@/models/types';
import MvPageTab from '@/components/common/tab/MvPageTab.vue';
import NftCardQtnrInfoComp from '@/components/common/modal/comp/NftCardQtnrInfoComp.vue';

export default defineComponent({
  name: "NftCardInfoComp",
  emit: ["cancel", "close"],
  components: {
    MvPageTab,
    NftCardQtnrInfoComp,
  },
  props: {
      title: {
          default: "",
      },
      data: {
          default: {
            priceUnit: '',
            cltnId: 0,
            qtnrInfo: {},
            qtnrTemplate: []
          },
      },
      isHeader: {
        default: true,
      }
  },
  setup(props, context) {
    const closeSlide = () => {
        context.emit("cancel");
    };
    const currency = computed(() => {
      return CURRENCY_TYPE[props.data.priceUnit as CurrencyType]
    })
    const selectedInfo = ref('nft')
    const isQtnr = computed(() => {
      return props.data?.qtnrTemplate?.length
    })
    return {
      closeSlide,
      currency,
      selectedInfo,
      isQtnr,
    };
  },
});
