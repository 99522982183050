
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    width: {
      default: 28,
    },
    height: {
      default: 28,
    },
    fill: {
      default: "currentColor",
    },
    viewBox: {
      default: "0 0 28 28",
    },
    disabled: {
      default: false,
    },
  },
})
