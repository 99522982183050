import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "blank-layout-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonHeader = _resolveComponent("CommonHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_home_layout_footer = _resolveComponent("home-layout-footer")!
  const _component_CommonFooter = _resolveComponent("CommonFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["blank-layout", _ctx.className])
    }, [
      (_ctx.$route.meta.back && _ctx.$route.query.code !== '401')
        ? (_openBlock(), _createElementBlock("header", {
            key: 0,
            class: _normalizeClass(["header-row", { 'theme-header': _ctx.$route.meta.isWlStyle }])
          }, [
            _createVNode(_component_CommonHeader)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("section", null, [
        _createVNode(_component_router_view)
      ]),
      (_ctx.$route.meta?.blankTheme === 3)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "theme-3",
            style: _normalizeStyle({ top: `${_ctx.theme3Top()}px` })
          }, null, 4))
        : _createCommentVNode("", true),
      (_ctx.$route.meta.isFooter)
        ? (_openBlock(), _createElementBlock("footer", _hoisted_1, [
            _createVNode(_component_home_layout_footer)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.$route.meta.commonFooter)
      ? (_openBlock(), _createBlock(_component_CommonFooter, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}