import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/cltn/:id',
    name: 'CltnNftDetailView',
    component: () => import('@/components/pages/detail/NftDetailView.vue'),
    meta: {
      layout: 'blank',
      blankTheme: 4,
    }
  },
  {
    path: '/cltn/info/:id',
    name: 'CltnDetailView',
    component: () => import('@/components/pages/cltn/CltnDetailView.vue'),
    meta: {
      layout: 'blank',
      blankTheme: 4,
    }
  },
];

export default routes