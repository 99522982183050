
import {defineComponent} from 'vue';
import BottomSlideSheet from '@/components/common/modal/BottomSlideSheet.vue';
import {useModal} from '@/script/common/modalScript';
import MvButton from '@/components/common/button/MvButton.vue';
import {useHomeListConfig, useHomeListModeConfig, useHomeListSortConfig, useNftFilter} from '@/script/homeScript';
import {NftSortFilter} from '@/models/types';

export default defineComponent({
  name: 'NftSortFilterComp',
  components: {
    MvButton,
    BottomSlideSheet
  },
  setup() {
    const {
      toggleModal,
      isOpen,
    } = useModal()

    const {
      listMode,
      isActive,
      changeMode,
    } = useHomeListModeConfig()

    const {
      nftSort,
      changeSort,
    } = useHomeListSortConfig()

    const {
      viewMode,
    } = useHomeListConfig()

    const initConfig = () => {
      selected.sort = nftSort.value
      selected.status = isActive.value
    }

    const openSheet = () => {
      initConfig()
      toggleModal(true)
    }

    const {
      sortOption,
      statusOption,
      selected,
    } = useNftFilter()

    const selectSort = (value: NftSortFilter) => {
      selected.sort = value
    }

    const selectStatus = (value: boolean) => {
      selected.status = value
    }

    const applyFilter = () => {
      if (selected.sort !== nftSort.value) {
        changeSort(selected.sort)
      }
      if (selected.status !== isActive.value) {
        changeMode(selected.status)
      }
      toggleModal(false)
    }

    return {
      toggleModal,
      isOpen,
      sortOption,
      statusOption,
      selected,
      selectSort,
      selectStatus,
      openSheet,
      applyFilter,
      viewMode,
    }
  }
})
