import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/invite',
    name: 'InviteView',
    component: () => import('@/components/pages/invite/InviteView.vue'),
    meta: {
      noHeader: true,
      layout: 'list',
    }
  },
];

export default routes
