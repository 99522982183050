import {RouteRecordRaw} from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/brand/:id',
    name: 'BrandDetailView',
    component: () => import('@/components/pages/brand/BrandDetailView.vue'),
    meta: {
      layout: 'blank',
      blankTheme: 0,
    },
  },
  {
    path: '/brand/cltn/:id',
    name: 'BrandCltnListView',
    component: () => import('@/components/pages/brand/BrandCltnListView.vue'),
    meta: {
      layout: 'list',
      noHeader: true,
    },
  },
];

export default routes;
