import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import AuthRoute from "./routes/auth_routes";
import HomeRoute from "./routes/home_routes";
import NftRoute from "./routes/nft_routes";
import CouponRoute from "./routes/coupon_routes";
import CltnRoute from "./routes/cltn_routes";
import BrandRoute from "./routes/brand_routes";
import ProfileRoute from "./routes/profile_routes";
import SearchRoute from "./routes/search_routes";
import InviteRoute from "./routes/invite_routes";
import DiscoverRoute from "./routes/discover_routes";
import EventRoute from "./routes/event_routes";
import PayRoute from "./routes/pay_routes";
import { checkLogin } from "@/script/auth/loginScript";
import { store } from "@/store";
import {
  chagneTheme,
} from "@/script/flutter/flutterBridgeScript";
import {
  ERR_ROUTE_STORAGE,
  EXT_STORAGE,
  HID_LINK,
  INIT_LOGIN,
  PASS_STORAGE,
  Q_STORAGE,
  ROUTE_STORAGE,
  WHITE_LABEL
} from '@/config/constants';
import {toggleAppLoading} from '@/script/common/loadingScript';
import {linkExternalUserApi} from '@/api/userApis';

const routes: Array<RouteRecordRaw> = [
  ...AuthRoute,
  ...HomeRoute,
  ...NftRoute,
  ...CouponRoute,
  ...CltnRoute,
  ...ProfileRoute,
  ...BrandRoute,
  ...SearchRoute,
  ...InviteRoute,
  ...DiscoverRoute,
  ...EventRoute,
  ...PayRoute,
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    redirect: () => {
      return { name: "HomeView" };
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.hash && savedPosition) {
      document.body.scrollTo(savedPosition.top, savedPosition.left);
      return savedPosition;
    } else if (!to.hash) {
      return {
        top: 0,
        left: 0,
        behavior: "auto",
      };
    }
  },
});

router.beforeEach(async (to, from) => {
  try {
    if (to.query.ext) {
      sessionStorage.setItem(EXT_STORAGE, to.query.ext as string)
      if (sessionStorage.getItem(EXT_STORAGE)) {
        linkExternalUserApi({
          userExtId: sessionStorage.getItem(EXT_STORAGE) as string
        })
        sessionStorage.removeItem(EXT_STORAGE)
      }
    }
    if (to.query.hidl) {
      sessionStorage.setItem(HID_LINK, to.query.hidl as string)
    }
    if (to.query.wl) {
      store.dispatch('label/setLabel', { name: to.query.wl })
      sessionStorage.setItem(WHITE_LABEL, to.query.wl as string)
    }
    if (to.query.q) {
      sessionStorage.setItem(Q_STORAGE, to.query.q as string)
    }
    if (to.query.route) {
      sessionStorage.setItem(ROUTE_STORAGE, to.query.route as string)
    }
    if (to.query.errRoute) {
      sessionStorage.setItem(ERR_ROUTE_STORAGE, to.query.errRoute as string)
    }
    if (to.query.pass) {
      sessionStorage.setItem(PASS_STORAGE, to.query.pass as string)
    }
    if (to.query[INIT_LOGIN]) {
      sessionStorage.setItem(INIT_LOGIN, to.query[INIT_LOGIN] as string)
    }
    if (from.path === sessionStorage.getItem(ROUTE_STORAGE)?.split('?')[0] && to.name === 'HomeView') {
      sessionStorage.removeItem(ROUTE_STORAGE)
    }
    toggleAppLoading(false)
    chagneTheme({ to: to.fullPath });
    // if (checkFlutter()) {
    //   chagneTheme({to: to.fullPath});
    // }
    if (!to.meta.unAuth) {
      const res = await checkLogin();
      store.commit("auth/FETCH_AUTH", res);
      if (res) {
        if (!res.termsCheck) return { name: 'RegisterExternal', params: { platform: 'common'} }
        if (res.isFirst) return { name: 'RegisterSuccessView' }
      }
    }
    if (to.meta.notLogin && to.query.code !== '401') {
      if (store.state.auth.id) {
        return { name: "HomeView" };
      }
    }
  } catch (e) {
    console.error(e);
  }
});

export default router;
