import {RouteLocationNamedRaw} from 'vue-router';
import router from '@/router';

export const goBack = (back?: any) => {
  const metaBack = router.currentRoute.value.meta.back
  if (back) {
    router.replace(back)
  } else if (metaBack) {
    if (metaBack === 'back') history.back()
    else router.replace(router.currentRoute.value.meta.back as RouteLocationNamedRaw)
  } else {
    history.back()
  }
}

export const useOrderStage = (type: string[]) => {
  let middleProcess = ['PayGroupSelectView']
  if (type.includes('seat')) middleProcess = ['PaySeatSelectView', 'PayPriceSelectView']
  if (type.includes('qtnr')) middleProcess.push('PayCustomerInputView')
  const stageList = ['PayDateTimeView', ...middleProcess, 'PayGroupDetailView']

  const getNextStage = (stageName: string) => {
    const index = stageList.findIndex(stage => stage === stageName)
    if (index === -1) return ''
    else return stageList[index + 1]
  }

  const getBackGoNum = (curRouteName: string, toRouteName: string) => {
    const curIndex = stageList.findIndex(routeName => routeName === curRouteName)
    const toIndex = stageList.findIndex(routeName => routeName === toRouteName)
    return toIndex - curIndex
  }

  return {
    stageList,
    getNextStage,
    getBackGoNum,
  }
}
