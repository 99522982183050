
import {computed, defineComponent} from 'vue';
import DiscoverLayoutHeader from '@/components/layout/discover_layout/DiscoverLayoutHeader.vue';
import HomeLayoutFooter from '@/components/layout/home_layout/HomeLayoutFooter.vue';
import CltnTypeFilterComp from '@/components/pages/discover/comp/CltnTypeFilterComp.vue';
import EventSortFilterComp from '@/components/pages/discover/comp/EventSortFilterComp.vue';
import {store} from '@/store';
import {useRoute} from 'vue-router';
import {useHomeListConfig} from '@/script/homeScript';
import CommonFooter from '@/components/layout/CommonFooter.vue';

export default defineComponent({
  name: 'DiscoverLayout.vue',
  components: {
    CommonFooter,
    DiscoverLayoutHeader,
    HomeLayoutFooter,
    CltnTypeFilterComp,
    EventSortFilterComp,
  },
  setup() {
    const route = useRoute()
    const modeClass = computed(() => {
      if (route.name === 'HomeView') return store.getters['status/getNftListClass']
      return 'status-able'
    })
    const {
      viewMode,
    } = useHomeListConfig()
    return {
      modeClass,
      viewMode,
    }

  }
})
