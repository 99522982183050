
import {computed, defineComponent} from "vue";

export default defineComponent({
  name: "MvProgressBar",
  props: {
    data: {
      default: {
        start: 0,
        end: 10,
        current: 4,
      }
    }
  },
  setup(props) {
    const rate = computed(() => {
      const data = props.data
      return (data.current - data.start) * 100 / (data.end - data.start)
    })

    return {
      rate,
    }
  }
})
