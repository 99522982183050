
import { defineComponent } from 'vue';
import MvButton from '@/components/common/button/MvButton.vue';

export default defineComponent({
  name: 'UserInviteComp',
  emits: ['ok'],
  props: {
    info: {
      default: {
        id: 0,
        type: '',
        email: '',
      },
    },
  },
  components: { MvButton },
  setup() {
    return {};
  },
});
