
import {defineComponent, onUnmounted, reactive, ref} from "vue";
import {isMobileDevice} from '@/utils/windowUtils';

export default defineComponent({
  name: "BottomSlideSheet",
  methods: {isMobileDevice},
  emit: ["update:visible", "cancel"],
  props: {
    title: {
      default: ''
    },
    footer: {
      default: false,
    },
    visible: {
      default: false,
    },
    duration: {
      default: 0.5,
    },
    hideExit: {
      default: false,
    },
    maxHeight: {
      default: false,
    }
  },
  setup(props, context) {
    const closeSlide = () => {
      context.emit("cancel")
      document.body.style.overflow = 'auto'
      context.emit("update:visible", false);
    }
    const footerRef = ref();
    const contentRef = ref();
    const frameRef = ref();
    const start = ref();
    const diff = ref();
    const heights = reactive({
      frame: {
        default: -1,
        start: -1,
      },
      content: {
        default: -1,
        start: -1,
      },
      footer: {
        default: -1,
      },
    });
    const getFooterBottom = () => {
      return footerRef.value.getBoundingClientRect().bottom;
    };
    const setDefault = (state: 'set' | 'default') => {
      if (heights.frame.default < 0) {
        heights.frame.default = frameRef.value.offsetHeight;
      } else if (state === 'default') frameRef.value.style.height = `${heights.frame.default}px`;
      if (heights.content.default < 0) {
        heights.content.default = contentRef.value.offsetHeight;
      } else if (state === 'default')
        contentRef.value.style.height = `${heights.content.default}px`;
      if (props.footer) heights.footer.default = getFooterBottom();
    };
    const startTouch = (e: TouchEvent) => {
      setDefault('set');
      diff.value = 0;
      start.value = e.targetTouches[0].screenY;
      heights.frame.start = frameRef.value.offsetHeight;
      heights.content.start = contentRef.value.offsetHeight;
    };

    const moveTouch = (e: TouchEvent) => {
      // if (props.footer && heights.footer.default !== getFooterBottom()) return
      const changeY = e.changedTouches[0].screenY;
      diff.value = start.value - changeY;
      if (diff.value <= 0 && !props.hideExit) {
        frameRef.value.style.height = `${heights.frame.start + diff.value}px`;
        contentRef.value.style.height = `${heights.content.start + diff.value}px`;
      }
    };

    const contentMoveTouch = (e: TouchEvent) => {
      const changeY = e.changedTouches[0].screenY;
      diff.value = start.value - changeY;
      if (diff.value <= 0 && contentRef.value.scrollTop === 0 && !props.hideExit) {
        frameRef.value.style.height = `${heights.frame.start + diff.value}px`;
        contentRef.value.style.height = `${heights.content.start + diff.value}px`;
      }
    };

    const endTouch = () => {
      if (heights.frame.default  > frameRef.value.offsetHeight + 50) {
        closeSlide();
      } else {
        contentRef.value.style.touchAction = 'auto'
        if (!props.maxHeight) {
          frameRef.value.style.height = `fit-content`;
          contentRef.value.style.height = `fit-content`;
        }
      }
    };

    const clickBack = () => {
      if (!props.hideExit) {
        closeSlide()
      }
    }


    onUnmounted(() => {
      document.body.style.overflow = 'auto'
    })

    return {
      closeSlide,
      startTouch,
      endTouch,
      moveTouch,
      contentMoveTouch,
      frameRef,
      contentRef,
      footerRef,
      clickBack,
    }
  }
})
