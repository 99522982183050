import { Module } from "vuex";
import { RootState } from "../index";
export interface ListState {
  nft: {
    list: any[];
  };
}

export const listModule: Module<ListState, RootState> = {
  namespaced: true,
  state: () => ({
    nft: {
      list: [],
    },
  }),
  mutations: {},
  actions: {
    fetchNftList: async (payload) => {
      console.log(payload);
    },
  },
  getters: {},
};
