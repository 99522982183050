
import {defineComponent} from 'vue';
import MvTab from '@/components/common/tab/MvTab.vue';
import {useHomeListCtgyConfig} from '@/script/homeScript';

export default defineComponent({
  name: 'NftTypeFilterComp',
  components: {
    MvTab,
  },
  emits: ['update:selected'],
  props: {
    selected: {
      default: "",
    },
  },
  setup(props, context) {
    const {
      changeCtgy,
      nftCtgy,
    } = useHomeListCtgyConfig()
    const filterList = [
      {
        label: 'nftType.all',
        value: ''
      },
      {
        label: 'nftType.MEMBERSHIP',
        value: 'MEMBERSHIP',
      },
      {
        label: 'nftType.TICKET',
        value: 'TICKET',
      },
    ]

    const updateSelected = (value: string) => {
      context.emit('update:selected', value)
    }

    return {
      filterList,
      nftCtgy,
      changeCtgy,
      updateSelected,
    }
  }
})
