import apiCaller from './index';
import {CltnListRequest} from '@/models/request/cltnRequestModel';
import jwtDecode from "jwt-decode";
import {JwtDecodeResult} from '@/models/commonModels';

export async function getCltnListApi(request: CltnListRequest) {
  const response = await apiCaller.get('/cltns', {query: request}, {});
  return response.data;
}

export async function getCltnNftListApi(cltnId: number) {
  const response = await apiCaller.get('/cltns/:id/nfts', {params: {id: cltnId}}, {});
  return response.data;
}

export async function getCltnDetailApi(cltnId: string) {
  const response = await apiCaller.get('cltns/:id', {params: {id: cltnId}}, {});

  return response.data;
}

export async function getQrNftDataApi(token: string) {
  const decoded: JwtDecodeResult = jwtDecode(token)
  if (decoded?.code[0] === 'C') {
    const response = await apiCaller.get('/cltns/qr/nft', {query: {token}}, {});
    return response.data;
  }
  if (decoded?.code[0] === 'N') {
    const response = await apiCaller.get('/nft-groups/qr/nft', {query: {token}}, {});
    return response.data;
  }
}

export async function createCltnNftEventApi(token: string) {
  const decoded: JwtDecodeResult = jwtDecode(token)
  if (decoded?.code[0] === 'C') {
    const response = await apiCaller.post('/cltns/qr/nft', {body: {token}}, {});
    return response.data;
  }
  if (decoded?.code[0] === 'N') {
    const response = await apiCaller.post('/nft-groups/qr/nft', {body: {token}}, {});
    return response.data;
  }
}
