import { Module } from 'vuex'
import { RootState } from '../index'
import MvTicketLogo from '@/components/svg/logo/MvTicketLogo.vue';
import MvStartLogo from '@/components/svg/logo/MvStartLogo.vue';
import MvLogo from '@/components/svg/logo/MvLogo.vue';
import {getLabelInfoApi} from '@/api/labelApis';
export interface LabelState {
  name: string,
  loginMethod: string[];
  startLogo: any;
  poweredLogo: any;
  logo: any;
}

export const labelModule: Module<LabelState, RootState> = {
  namespaced: true,
  state: () => ({
    name: 'default',
    loginMethod: ['google', 'kakao', 'email'],
    startLogo: MvStartLogo,
    poweredLogo: MvTicketLogo,
    logo: MvLogo
  }),
  mutations: {
    SET_LABEL: (state, payload) => {
      state.name = payload.name
      state.logo = payload.logoImg
      state.startLogo = payload.startLogoImg
      state.loginMethod = payload.loginMethod
    }
  },
  actions: {
    setLabel: async ({ commit }, { name }) => {
      const labelInfo = await getLabelInfoApi(name)
      if (labelInfo) commit('SET_LABEL', labelInfo)
    },
  },
  getters: {
    getLabel: () => {
      return {}
    }
  }
}
