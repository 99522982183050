<template>
  <div>
    <vue3-lottie
        ref="lottieRef"
        :animation-data="complete"
        :width="width"
        :height="height"
        :loop="loop"
    />
  </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import 'vue3-lottie/dist/style.css'
import {Vue3Lottie} from "vue3-lottie";
import complete from '@/assets/lotties/cplt_round.json';

export default defineComponent({
  name: "CpltRoundLottie",
  components: {
    Vue3Lottie
  },
  props: {
    loop: {
      default: false
    },
    width: {
      default: '140px'
    },
    height: {
      default: '140px'
    }
  },
  setup(props) {
    const lottieRef = ref();
    const play = () => {
      lottieRef.value.play();
    }
    return {
      complete,
      lottieRef,
      play,
    }
  }
})
</script>
