import apiCaller from './index';
import {
  NtfcListResponse,
  NtfcNftReceiveResponse,
  NtfcUserInviteResponse,
} from '@/models/response/ntfcResponseModel';

export const getUncheckedNtfcListApi = async (): Promise<NtfcListResponse> => {
  const { data } = await apiCaller.get('/notifications/unconfirm', {}, {});
  return data;
};

export async function getUncheckedNtfcApi(
  id: number
): Promise<NtfcNftReceiveResponse | NtfcUserInviteResponse> {
  const { data } = await apiCaller.get(`/notifications/unconfirm/${id}`, {}, {});
  return data;
}

export async function checkNtfcApi(ntfcId: string) {
  const { data } = await apiCaller.post(`/notifications/${ntfcId}/confirm`, {}, {});
  return data;
}
