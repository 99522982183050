import router from '@/router';
import {store} from '@/store';
import {computed} from 'vue';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const flutter = window.messageHandler;

export function checkFlutter() {
  if (navigator.userAgent.includes('in-app-browser')) {
    return true;
  } else {
    return false;
  }
}

export function chagneTheme({theme, to}: {theme?: string | null; to?: string | null}) {
  if (checkFlutter()) {
    const nftListStatus = computed(() => {
      return store.state.status.nftList.mode;
    });
    const couponListStatus = computed(() => store.state.status.couponList.mode);
    if (to === '/qr') {
      flutter.postMessage(JSON.stringify({message: 'theme', theme: 'white'}));
      return;
    } else if (to === '/') {
      if (nftListStatus.value === 'able') {
        flutter.postMessage(JSON.stringify({message: 'theme', theme: 'white'}));
        return;
      } else {
        flutter.postMessage(JSON.stringify({message: 'theme', theme: 'dark'}));
        return;
      }
    } else if (to === '/coupons') {
      if (couponListStatus.value === 'able') {
        flutter.postMessage(JSON.stringify({message: 'theme', theme: 'white'}));
        return;
      } else {
        flutter.postMessage(JSON.stringify({message: 'theme', theme: 'dark'}));
        return;
      }
    } else if (theme) {
      flutter.postMessage(JSON.stringify({message: 'theme', theme: theme}));
    } else {
      flutter.postMessage(JSON.stringify({message: 'theme', theme: 'white'}));
    }
  }
}

export function openQrScanner(from: string) {
  if (checkFlutter()) {
    flutter.postMessage(JSON.stringify({message: 'camera', from: from}));
  }
}

export function flutterRouterBack() {
  router.back();
  return;
}
