import { Module } from 'vuex'
import {RootState} from '../index'
import dayjs from 'dayjs';
import {getCltnNftGroupListApi, getSeatRefTimeApi} from '@/api/discoverApis';
import {HID_LINK} from '@/config/constants';
import {TimeItem} from '@/models/orderModels';
export interface ProductInfo {
  productId: string;
  count: number;
}
export interface OrderState {
  process: string[],
  cltn: any,
  qtnrTemplate: any[],
  seat: any,
  isQtnr: boolean,
  customer: any;
  selectList: ProductInfo[];
  seatRefTime: number;
  requested: boolean;
  ticket: {
    groupList: any[],
    date: {
      startDate: string;
      endDate: string;
      cltnTimeId?: number;
    }
  };
}

const initTicket = () => {
  return {
    selectList: [],
    groupList: [],
    date: {
      startDate: '',
      endDate: '',
    },
  }
}

const getDefaultQtnr = () => {
  return [
    {
      name: 'key:name',
      required: true
    },
    {
      name: 'key:phone',
      required: true
    }
  ]
}
export const orderModule: Module<OrderState, RootState> = {
  namespaced: true,
  state: () => ({
    process: [],
    cltn: {},
    seat: [],
    qtnrTemplate: getDefaultQtnr(),
    isQtnr: false,
    customer: null,
  selectList: [],
    ticket: initTicket(),
    seatRefTime: 0,
    requested: false,
  }),
  mutations: {
    INIT_TICKET: (state) => {
      state.process = []
      state.cltn = {}
      state.ticket = initTicket()
      state.qtnrTemplate = getDefaultQtnr()
      state.customer = null
      state.isQtnr = false
      state.selectList = []
      state.seat = []
      state.seatRefTime = 0
      state.requested = false
    },
    INIT_SEAT: (state) => {
      state.selectList = []
      state.seat = []
      state.seatRefTime = 0
    },
    COMMIT_TICKET_DATE: (state, { startDate, endDate, cltnTimeId }) => {
      state.ticket.date.startDate = startDate
      state.ticket.date.endDate = endDate
      state.ticket.date.cltnTimeId = cltnTimeId
    },
    FETCH_QTNR: (state, qtnr) => {
      state.qtnrTemplate = qtnr
      state.isQtnr = true
    },
  },
  actions: {
    updateNftGroup: async ({ state }) => {
      const cltnId = state.cltn.id
      if (!cltnId) return
      const { list } = await getCltnNftGroupListApi(cltnId)
      state.ticket.groupList = list.map((item: any) => {
        return {
          ...item,
          maxCnt: Math.min(item.mintableCnt, item.mintableCntPerWallet - item.buyCnt)
        }
      })
    },
    setSeatRefTime: async ({ state}) => {
      const { endTime } = await getSeatRefTimeApi()
      state.seatRefTime = endTime
    }
  },
  getters: {
    getCltnId: (state) => {
      return state.cltn.id
    },
    isSelectEmpty: (state) => {
      return !state.selectList.length
    },
    getDateOptions: (state) => {
      const options: any[] = []
      const groupList = state.ticket.groupList.toSorted((a, b) => {
        return dayjs(a.attributes.startDate).isBefore(b.attributes.startDate) ? -1 : 1
      })
      if (groupList.length) {
        for (const group of groupList) {
          const att = group.attributes
          //이벤트 후 수정
          const saleEndTime = att.startDate
          if (group.mintableCnt > 0 && dayjs(saleEndTime).isAfter(dayjs())) {
            const timeItem = {
              startDate: att?.startDate,
              endDate: att?.endDate,
              cltnTimeId: group.cltnTimeId,
            }
            const findIndex = options.findIndex(({date}) => {
              if (date) {
                return dayjs(date).isSame(att?.startDate, 'day')
              }
              return false
            })
            if (findIndex === -1) {
              options.push({
                date: att?.startDate,
                timeZone: att?.timeZone,
                timeList: [timeItem],
              })
            } else {
              if (!options[findIndex].timeList.some((time: TimeItem) => {
                return time.startDate === timeItem.startDate && time.endDate === timeItem.endDate
              })) {
                options[findIndex].timeList.push(timeItem)
              }
            }
          }
        }
      }
      return options.sort((a, b) => {
        return dayjs(a.date).isBefore(b.date) ? -1 : 1
      })
    },
    getGroupOptions: (state) => {
      return state.ticket.groupList.filter(group => {
        // iam hidden
        if ( sessionStorage.getItem(HID_LINK) !== 'iamhidl' && process.env.VUE_APP_EX_NG) {
          const excludeList = process.env.VUE_APP_EX_NG.split(';')
          if (excludeList.includes(group.id + '')) return false
        }
        const att = group.attributes
        const ticketDate = state.ticket.date
        return att.startDate === ticketDate.startDate && att.endDate === ticketDate.endDate
      }).map(option => {
        return {
          ...option,
          disabled: option.maxCnt === 0,
        }
      })
    },
    isQtnrTemplateEmpty: (state) => {
      return !state.qtnrTemplate.length
    },
  },
}
