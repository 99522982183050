import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/profile',
    name: 'MyProfileView',
    component: () => import('@/components/pages/profile/MyProfileView.vue'),
    meta: {
      noHeader: true,
      layout: 'list',
    }
  },
  {
    path: '/profile/edit',
    name: 'EditProfileView',
    component: () => import('@/components/pages/profile/EditProfileView.vue'),
    meta: {
      layout: 'blank',
    }
  },
  {
    path: '/profile/change/auth/:stage',
    name: 'ChangeAuthPwdView',
    component: () => import('@/components/pages/profile/ChangeAuthPwdView.vue'),
    meta: {
      layout: 'blank',
      back: { name: 'MyProfileView' }
    }
  },
  {
    path: '/profile/auth/2step',
    name: '2StepAuthView',
    component: () => import('@/components/pages/profile/2StepAuthView.vue'),
    meta: {
      layout: 'blank',
      back: { name: 'MyProfileView' }
    }
  },
  {
    path: '/profile/transaction',
    name: 'MyTrstView',
    component: () => import('@/components/pages/profile/MyTrstView.vue'),
    meta: {
      layout: 'blank',
      back: { name: 'MyProfileView' }
    }
  },
];

export default routes
