import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('@/components/pages/home/HomeView.vue'),
    meta: {
      layout: 'list'
    }
  },
  {
    path: '/qr',
    name: 'QrScanView',
    component: () => import('@/components/pages/qr/QrScanView.vue'),
    meta: {
      noHeader: true,
      layout: 'list'
    }
  },
  {
    path: '/qr/mint',
    name: 'QrMintView',
    component: () => import('@/components/pages/qr/QrMintView.vue'),
    meta: {
      noHeader: true,
      layout: 'blank'
    }
  },
];

export default routes
