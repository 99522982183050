import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "home-layout-header-row"
}
const _hoisted_2 = {
  key: 1,
  class: "filter-section"
}
const _hoisted_3 = {
  key: 0,
  class: "filters-group"
}
const _hoisted_4 = {
  key: 1,
  class: "filters-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_layout_header = _resolveComponent("home-layout-header")!
  const _component_nft_type_filter_comp = _resolveComponent("nft-type-filter-comp")!
  const _component_nft_sort_filter_comp = _resolveComponent("nft-sort-filter-comp")!
  const _component_cltn_type_filter_comp = _resolveComponent("cltn-type-filter-comp")!
  const _component_cltn_sort_filter_comp = _resolveComponent("cltn-sort-filter-comp")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_home_layout_footer = _resolveComponent("home-layout-footer")!
  const _component_CommonFooter = _resolveComponent("CommonFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["home-layout", _ctx.modeClass])
    }, [
      (!_ctx.$route.meta.noHeader)
        ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
            _createVNode(_component_home_layout_header)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.$route.meta.noHeader)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.viewMode === 'nft')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_nft_type_filter_comp, {
                    selected: _ctx.nftCtgy,
                    "onUpdate:selected": _ctx.changeCtgy
                  }, null, 8, ["selected", "onUpdate:selected"]),
                  _createVNode(_component_nft_sort_filter_comp)
                ]))
              : _createCommentVNode("", true),
            (_ctx.viewMode === 'cltn')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_cltn_type_filter_comp),
                  _createVNode(_component_cltn_sort_filter_comp)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("section", null, [
        _createVNode(_component_router_view)
      ]),
      _createElementVNode("footer", null, [
        _createVNode(_component_home_layout_footer)
      ])
    ], 2),
    _createVNode(_component_CommonFooter)
  ], 64))
}