import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["type", "disabled"]
const _hoisted_2 = {
  key: 0,
  style: {"visibility":"hidden"}
}
const _hoisted_3 = {
  key: 1,
  class: "dot-loader"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["mv-button", _ctx.btnClass])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.typeClass)
    }, [
      _createElementVNode("button", {
        type: _ctx.htmlType,
        disabled: _ctx.disabled
      }, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "`"))
          : _createCommentVNode("", true),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3))
          : _renderSlot(_ctx.$slots, "content", { key: 2 }, () => [
              (_ctx.i18n)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.text)), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.text), 1))
            ])
      ], 8, _hoisted_1)
    ], 2)
  ], 2))
}