
import {defineComponent} from 'vue';
import BottomSlideSheet from '@/components/common/modal/BottomSlideSheet.vue';
import {useModal} from '@/script/common/modalScript';
import MvButton from '@/components/common/button/MvButton.vue';
import {
  useCltnListFilter,
  useNftFilter
} from '@/script/homeScript';
import {NftSortFilter} from '@/models/types';

export default defineComponent({
  name: 'NftSortFilterComp',
  components: {
    MvButton,
    BottomSlideSheet
  },
  setup() {
    const {
      toggleModal,
      isOpen,
    } = useModal()

    const {
      filters,
      changeSort,
    } = useCltnListFilter()

    const initConfig = () => {
      selected.sort = filters.value.sort
    }

    const openSheet = () => {
      initConfig()
      toggleModal(true)
    }

    const {
      sortOption,
      selected,
    } = useNftFilter()

    const selectSort = (value: NftSortFilter) => {
      selected.sort = value
    }

    const applyFilter = () => {
      if (selected.sort !== filters.value.sort) {
        changeSort(selected.sort)
      }
      toggleModal(false)
    }

    return {
      toggleModal,
      isOpen,
      sortOption,
      selected,
      selectSort,
      openSheet,
      applyFilter,
    }
  }
})
