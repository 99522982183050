import apiCaller from "./index";
import {BuyFreeRequest, GetDiscoverCltnListRequest, GetSeatInfoRequest} from '@/models/request/discoverRequestModel';
import {PagingRequest} from '@/models/request/commonRequestModel';
import {GetNftHistoryRequest} from '@/models/request/nftRequestModels';
import {GetCouponListRequest} from '@/models/request/couponRequestModel';
import {GetCltnTimeForCntValidRes, GetDiscoverCltnDetailRes} from '@/models/response/discoverResponseModel';
import instance from '@/api/config';

export async function getDiscoverCltnListApi(request: GetDiscoverCltnListRequest) {
  const response = await apiCaller.get("/discover/cltns", {
    query: request
  }, {});
  return response.data;
}

export async function getDiscoverCltnDetailApi(cltnId: string): Promise<GetDiscoverCltnDetailRes> {
  const response = await apiCaller.get("/discover/cltns/:id", {
    params: { id: cltnId },
  }, {});
  return response.data;
}

export async function getDiscoverNftListApi(cltnId: string, request: PagingRequest) {
  const response = await apiCaller.get("/discover/cltns/:id/nfts", {
    query: request,
    params: { id: cltnId },
  }, {});
  return response.data;
}

export async function getDiscoverNftDetailApi(nftId: string) {
  const response = await apiCaller.get("/discover/nfts/:id", {
    params: { id: nftId },
  }, {});
  return response.data;
}
export async function getDiscoverCltnSearch(keyword: string) {
  const response = await apiCaller.get("/discover/cltns/search", {
    query: { keyword },
  }, {});
  return response.data;
}

export async function getNftHistoryApi({
                                         id,
                                         ...request
                                       }: GetNftHistoryRequest) {
  const response = await apiCaller.get(
    "/discover/nfts/:id/histories",
    { params: { id }, query: request },
    {}
  );
  return response.data
}

export async function getCouponListApi(request: GetCouponListRequest) {
  const response = await apiCaller.get("/discover/nfts/:id/coupons", { query: request, params: {id: request.nftId} }, {});
  return response.data;
}

export async function getCltnApplyFormatApi(cltnId: string) {
  const response = await apiCaller.get("/discover/cltns/:cltnId/apply/format", { params: { cltnId } }, {});
  return response.data;
}

export async function getCltnApplyCanApi(cltnId: string) {
  const response = await apiCaller.get("/discover/cltns/:cltnId/apply/can", { params: { cltnId } }, {});
  return response.data;
}

export async function getCltnApplyWriterApi() {
  const response = await apiCaller.get("/discover/cltns/:cltnId/apply/writer", {}, {});
  return response.data;
}

export async function submitCltnApplyApi({ cltnId, form, isMode = false }: { cltnId: string; form: any, isMode: boolean }) {
  const formData = new FormData()
  let imgIndex = 0
  Object.entries(form).forEach(([key, value]) => {
    if (value instanceof File) {
      formData.append(key, `image;${imgIndex}`)
      formData.append('images', value)
      imgIndex += 1
    } else if (Array.isArray(value)) {
      value.forEach(v => formData.append(key, v as string))
    } else {
      formData.append(key, value as string)
    }
  })
  if (isMode) {
    const response = await apiCaller.put("/discover/cltns/:cltnId/apply", {
      params: {
        cltnId
      },
      body: formData
    }, {});
    return response.data;
  } else {
    const response = await apiCaller.post("/discover/cltns/:cltnId/apply", {
      params: {
        cltnId
      },
      body: formData
    }, {});
    return response.data;
  }
}

export async function getCltnNftGroupListApi(cltnId: string) {
  const response = await apiCaller.get("/discover/cltns/:id/nft_groups", { params: {id: cltnId} }, {});
  return response.data;
}

export async function getNftGroupListWithPriceApi(idList: string[], priceUnit: string) {
  const response = await apiCaller.get("discover/nft_groups/price", { query: { idList, priceUnit } }, {});
  return response.data;
}

export async function getNftGroupDetailApi(nftGroupId: string) {
  const response = await apiCaller.get("discover/nft_groups/:id", { params: { id: nftGroupId } }, {});
  return response.data;
}

export async function reserveOrderApi(nftGroupId: string) {
  const response = await apiCaller.post("discover/orders/reservation", { query: { nftGroupId } }, {});
  return response.data;
}

export async function payOrderApi(orderId: string, request: any) {
  const response = await apiCaller.post("discover/orders/:id/payment", { params: { id: orderId }, body: request }, {});
  return response.data;
}

export async function getRefundInfoApi(nftOrderId: number) {
  const response = await apiCaller.get("discover/orders/nft/refund/:id", { params: { id: nftOrderId } }, {});
  return response.data;
}

export async function refundNftOrderApi(nftOrderId: number) {
  const response = await apiCaller.put("discover/orders/nft/:id", { params: { id: nftOrderId } }, {});
  return response.data;
}

export async function refundOrderApi(orderId: string) {
  const response = await apiCaller.put("discover/orders/:id", { params: { id: orderId } }, {});
  return response.data;
}

export async function buyFreeApi(request: BuyFreeRequest) {
  const response = await apiCaller.post("discover/payment/free", { body: request }, {});
  return response.data;
}

export async function getSeatInfoApi(request: GetSeatInfoRequest) {
  const response = await apiCaller.get("discover/seats/:placeId", {
    params: {
      placeId: request.placeId,
    },
    query: {
      startDate: request.startDate,
      cltnId: request.cltnId,
    }
  }, {});
  return response.data;
}

export async function reserveSeatApi(idList: any[]) {
  const response = await apiCaller.put("discover/seats/reserve", {
    body: {
      idList
    }
  }, {});
  return response.data;
}

export async function clearReservedSeatApi() {
  const response = await apiCaller.put("discover/seats/reserve/clear", {}, {});
  return response.data;
}

export async function getSeatRefTimeApi() {
  const response = await apiCaller.get("discover/seats/reserve/time", {}, {});
  return response.data;
}

export async function getPriceTypeListBySeatApi(seatIdList: number[]) {
  const response = await apiCaller.get("discover/seats/nft_groups", {
    query: {
      seatIdList,
    },
  }, {});
  return response.data;
}

export async function getNftGroupPriceListApi(nftGroupId: number) {
  const response = await apiCaller.get("discover/nft_groups/prices", {
    query: {
      nftGroupId,
    },
  }, {});
  return response.data;
}

export async function getCltnTimeForCntValid(cltnTimeId: number): Promise<GetCltnTimeForCntValidRes> {
  const response = await apiCaller.get("discover/cltn_times/:cltnTimeId", {
    params: {
      cltnTimeId
    }
  }, {});
  return response.data;
}




