import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n';
import {store, key} from './store'
import "./serviceWorker";
import "@/assets/scss/site.scss";

declare global {
  interface Window {
    PaymentWidget: any
  }
}

createApp(App)
    .use(store, key)
    .use(router)
    .use(i18n)
    .mount('#app')

