
import {defineComponent} from 'vue';
import BottomSlideSheet from '@/components/common/modal/BottomSlideSheet.vue';
import {useModal} from '@/script/common/modalScript';
import MvButton from '@/components/common/button/MvButton.vue';
import {NftSortFilter} from '@/models/types';
import {useCltnFilter, useCltnListSortConfig} from '@/script/discover/discoverScript';

export default defineComponent({
  name: 'NftSortFilterComp',
  components: {
    MvButton,
    BottomSlideSheet
  },
  setup() {
    const {
      toggleModal,
      isOpen,
    } = useModal()

    const {
      cltnSort,
      changeSort,
    } = useCltnListSortConfig()

    const initConfig = () => {
      selected.sort = cltnSort.value
    }

    const openSheet = () => {
      initConfig()
      toggleModal(true)
    }

    const {
      sortOption,
      selected,
      sortLabel,
    } = useCltnFilter()

    const selectSort = (value: NftSortFilter) => {
      selected.sort = value
    }

    const applyFilter = () => {
      if (selected.sort !== cltnSort.value) {
        changeSort(selected.sort)
      }
      toggleModal(false)
    }

    selectSort(cltnSort.value)

    return {
      toggleModal,
      isOpen,
      sortOption,
      selected,
      selectSort,
      openSheet,
      applyFilter,
      sortLabel
    }
  }
})
