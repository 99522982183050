import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";
import { moduleAState, moduleA as ma } from "@/store/modules/moduleA.store";
import { AuthState, authModule as auth } from "@/store/modules/auth.store";
import {
  StatusState,
  statusModule as status,
} from "@/store/modules/status.store";
import { ListState, listModule as list } from "@/store/modules/list.store";
import {
  DetailState,
  detailModule as detail,
} from "@/store/modules/detail.store";
import { PopupState, popupModule as popup } from "@/store/modules/popup.store";
import {
  ElementState,
  elementModule as el,
} from "@/store/modules/element.store";
import {
  SearchState,
  searchModule as search,
} from "@/store/modules/search.store";
import {
  OrderState,
  orderModule as order,
} from '@/store/modules/order.store';
import {
  LabelState,
  labelModule as label,
} from '@/store/modules/label.store';

export interface RootState {
  ma: moduleAState;
  auth: AuthState;
  status: StatusState;
  list: ListState;
  detail: DetailState;
  popup: PopupState;
  el: ElementState;
  search: SearchState;
  order: OrderState;
  label: LabelState;
}

export const key: InjectionKey<Store<RootState>> = Symbol();

export function useStore() {
  return baseUseStore(key);
}

export const store = createStore<RootState>({
  modules: {
    ma,
    auth,
    status,
    list,
    detail,
    popup,
    el,
    search,
    order,
    label
  },
});
