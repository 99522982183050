import {computed, ref} from 'vue';
import {store} from '@/store';

export const useLoading = () => {
  const loading = ref(false)
  const toggleLoading = (value: boolean) => {
    if (value) loading.value = true
    else {
      setTimeout(() => {
        loading.value = false
      }, 1000)
    }
  }
  return {
    loading,
    toggleLoading,
  }
}

export const useAppLoading = () => {
  const appLoading = computed(() => {
    return store.state.status.loading
  })

  return {
    appLoading,
    toggleAppLoading,
  }
}

export const toggleAppLoading = (value: boolean) => {
  store.state.status.loading = value
}
