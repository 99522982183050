import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/discover',
    name: 'DiscoverView',
    component: () => import('@/components/pages/discover/DiscoverView.vue'),
    meta: {
      layout: 'discover',
    }
  },
  {
    path: '/discover/search',
    name: 'DiscoverSearchView',
    component: () => import('@/components/pages/discover/search/DiscoverSearchView.vue'),
    meta: {
      layout: 'blank',
    }
  },
  {
    path: '/discover/detail/:id',
    name: 'DiscoverDetailView',
    component: () => import('@/components/pages/discover/DiscoverEventDetailView.vue'),
    meta: {
      layout: 'blank',
      back: { name: 'DiscoverView' },
    }
  },
  {
    path: '/discover/pay/:id',
    name: 'PaymentView',
    component: () => import('@/components/pages/discover/PaymentView.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/discover/event/pay',
    name: 'DiscoverEventPaymentView',
    component: () => import('@/components/pages/discover/payment/DiscoverPaymentView.vue'),
    meta: {
      layout: 'blank'
    },
    children: [
      {
        path: '/discover/event/pay/date',
        name: 'PayDateTimeView',
        component: () => import('@/components/pages/discover/payment/PayDateTimeView.vue'),
        meta: {
          title: 'paySelect.dateTitle',
        }
      },
      {
        path: '/discover/event/pay/group',
        name: 'PayGroupSelectView',
        component: () => import('@/components/pages/discover/payment/PayGroupSelectView.vue'),
        meta: {
          title: 'paySelect.title',
        },
      },
      {
        path: '/discover/event/pay/seat',
        name: 'PaySeatSelectView',
        component: () => import('@/components/pages/discover/payment/PaySeatSelectView.vue'),
        meta: {
          title: 'paySelect.selectSeat',
        }
      },
      {
        path: '/discover/event/pay/price',
        name: 'PayPriceSelectView',
        component: () => import('@/components/pages/discover/payment/PayPriceSelectView.vue'),
        meta: {
          title: 'paySelect.dateTitle',
        }
      },
      {
        path: '/discover/event/pay/buyer',
        name: 'PayCustomerInputView',
        component: () => import('@/components/pages/discover/payment/PayCustomerInputView.vue'),
        meta: {
          title: 'paySelect.buyerInfo',
        },
      },
      {
        path: '/discover/event/pay/detail',
        name: 'PayGroupDetailView',
        component: () => import('@/components/pages/discover/payment/PayGroupDetailView.vue'),
        meta: {
          title: 'payDetail.title',
        },
      },
    ],
  },
  {
    path: '/discover/event/apply/form/:cltnId',
    name: 'ApplyFormView',
    component: () => import('@/components/pages/discover/apply/ApplyFormView.vue'),
    meta: {
      title: 'eventApply.contentTitle',
    }
  },

];

export default routes
