
import { defineComponent } from 'vue';
import MvButton from '@/components/common/button/MvButton.vue';
import CpltRoundLottie from '@/components/common/lottie/CpltRoundLottie.vue';

export default defineComponent({
  name: 'UserInviteComp',
  emits: ['ok'],
  props: {
    info: {
      default: {
        id: 0,
        type: '',
        name: '',
      },
    },
  },
  components: {CpltRoundLottie, MvButton },
  setup() {
    return {};
  },
});
