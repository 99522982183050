import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/coupons/:id',
    name: 'CouponDetailView',
    component: () => import('@/components/pages/detail/CouponDetailView.vue'),
    meta: {
      layout: 'blank',
      blankTheme: 4
    }
  },
  {
    path: '/coupons',
    name: 'CouponListView',
    component: () => import('@/components/pages/coupon/CouponListView.vue'),
    meta: {
      layout: 'blank',
      blankTheme: 4,
      isFooter: true,
      commonFooter: true,
    }
  },
];

export default routes
