import { Module } from "vuex";
import { RootState } from "../index";
import {SearchedCltn, SearchedNft} from '@/models/response/searchResponseModel';
export interface SearchState {
  nftList: SearchedNft[];
  cltnList: SearchedCltn[];
  searchKeyword: string;
  nftTotalCount: number;
  cltnTotalCount: number;
  isNftOrCltn: string;
}

export const searchModule: Module<SearchState, RootState> = {
  namespaced: true,
  state: () => ({
    nftList: [],
    cltnList: [],
    searchKeyword: "",
    nftTotalCount: 0,
    cltnTotalCount: 0,
    isNftOrCltn: "nft",
  }),
  mutations: {
    INIT_SEARCH: (state) => {
      state.nftList = [];
      state.cltnList = [];
      state.searchKeyword = "";
      state.nftTotalCount = 0;
      state.cltnTotalCount = 0;
      state.isNftOrCltn = "nft";
    },
    SET_NFT_LIST: (state, payload) => {
      state.nftList = payload;
      state.nftTotalCount = payload.totalCount;
    },
    SET_CLTN_LIST: (state, payload) => {
      state.cltnList = payload;
      state.cltnTotalCount = payload.totalCount;
    },
    SET_SEARCH_KEYWORD: (state, payload) => {
      state.searchKeyword = payload;
    },
    CHANGE_IS_NFT_OR_CLTN: (state, payload) => {
      state.isNftOrCltn = payload;
    },
  },
  actions: {},
  getters: {},
};
