import {HISTORY_TYPE, HistoryType, NFT_CTGY, NftCtgy} from '@/models/types';

export const getNftTCtgy = (value: NftCtgy) => {
  if (NFT_CTGY[value]) return NFT_CTGY[value]
  return {
    label: '',
    value: '',
    short: '',
    tag: '',
    i18n: '',
    i18nCount: '',
  }
}

export const getHistoryType = (value: HistoryType) => {
  if (HISTORY_TYPE[value]) return HISTORY_TYPE[value]
  return {
    label: '',
    value: '',
    bgColor: '',
    icon: require('@/assets/images/icons/invite_24.svg')
  }
}
