import dayjs from '@/utils/dayjs';

export const formatHistoryDate = (date: string, locale: string) => {
  if (locale === 'ko') return dayjs(date).locale('ko').format('lll')
  if (locale === 'en') dayjs(date).locale('en').format('lll')
  return dayjs(date).locale('en').format('lll')
}

export const formatDate = (date: any, options: {
  timeZone?: string;
  format :string;
}) => {
  const dayIns = dayjs(date)
  if (options.timeZone) {
    return dayIns.tz(options.timeZone).format(options.format)
  }
  let dayString = dayIns.format(options.format)
  if (dayIns.locale() === 'ko') {
    if (options.format === 'L') dayString = dayString.replace(RegExp(/\.(?=[^.]*$)/), "")
  }
  return dayString
}

export const convertToAllDay = (date: any) => {
  return dayjs(date).endOf('date')
}
