import { Module } from 'vuex'
import { RootState } from '../index'
export interface ElementState {
    focus: HTMLElement | null,
}

export const elementModule: Module<ElementState, RootState> = {
    namespaced: true,
    state: () => ({
        focus: null,
    }),
    mutations: {
        FETCH_FOCUS: (state, payload) => {
            state.focus = payload
        }
    },
    getters: {
        getFocusPosHeight: (state) => {
            if (state.focus) {
                const pos = state.focus.getBoundingClientRect()
                return pos
            }
            return null
        }
    },
}