import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "home-layout-header",
  ref: "titleRef"
}
const _hoisted_2 = { class: "title-section" }
const _hoisted_3 = { class: "tab-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_svg = _resolveComponent("search-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["tab-item", _ctx.tabClass(_ctx.viewMode, 'nft')]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleViewMode('nft')))
        }, _toDisplayString(_ctx.$t('home.myNft')), 3),
        _createElementVNode("div", {
          class: _normalizeClass(["tab-item", _ctx.tabClass(_ctx.viewMode, 'cltn')]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleViewMode('cltn')))
        }, _toDisplayString(_ctx.$t('home.myEvent')), 3)
      ]),
      _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.routeSearch && _ctx.routeSearch(...args))),
        class: "title-right"
      }, [
        _createVNode(_component_search_svg, { class: "cursor-pointer" })
      ])
    ])
  ], 512))
}