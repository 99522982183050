import { computed, reactive, ref } from 'vue';
import {modifyTermsCheckApi, RegisterPhoneApi, resendVerifyEmailApi, signupEmailApi} from '@/api/userApis';
import * as Yup from 'yup';
import { SignupEmailRequest } from '@/models/request/authRequestModel';
import { useGoogleLogin, useKaKaoLogin } from '@/script/auth/loginScript';
import { CustomError } from '@/config/errors';
import {PHONE_LENGTH} from '@/config/constants';
import {useI18n} from 'vue-i18n';
import {useRouter} from 'vue-router';

export const useRegisterEmail = () => {
  const form = reactive({
    email: '',
    name: '',
    pwd: '',
    pwdConfirm: '',
    allowAd: false,
  })

  const termsCheck = reactive({
    mooveTerms: false,
    indvTerms: false,
    marketingTerms: false,
  })

  const schema = Yup.object().shape({
    email: Yup.string().required('valid.required').email('valid.email'),
    pwd: Yup.string().required('valid.required')
      .matches(/^(?=.*[a-z])(?=.*[\d\s\W]).{8,}$/, 'valid.pwdRequirements'),
    pwdConfirm: Yup.string()
      .required('valid.required')
      .oneOf([Yup.ref('pwd')], 'valid.pwdConfirm'),
    mooveTerms: Yup.boolean().isTrue(),
    indvTerms: Yup.boolean().isTrue(),
  });

  const register = async (form: SignupEmailRequest) => {
    const request: SignupEmailRequest = {
      email: form.email?.toLowerCase(),
      name: form.name,
      pwd: form.pwd,
      allowAd: termsCheck.marketingTerms
    }
    if (form.url) request.url = form.url
    try {
      await signupEmailApi(request);
    } catch (e: any) {
      if (e instanceof CustomError) {
        const error = e.getError();
        switch (error.errorCode) {
          case "2001":
            throw { field: "email", message: 'valid.registered' };
        }
      }
      throw e;
    }
  }

  const canSubmit = computed(() => {
    return !!form.email
      && form.name
      && form.pwd
      && form.pwdConfirm
      && termsCheck.mooveTerms
      && termsCheck.indvTerms
  })

  return {
    form,
    termsCheck,
    schema,
    register,
    canSubmit,
  }
}

export const useResendRegisterEmail = () => {
  const resendEmail = async (email: string) => {
    await resendVerifyEmailApi(email);
  }

  return {
    resendEmail,
  }
}

export const useTermsAgree = () => {
  const terms = ref('');
  const termsVisible = ref(false);
  const toggleTerms = (state: boolean) => {
    termsVisible.value = state
  }
  const openTerms = (termsComp: string) => {
    terms.value = termsComp;
    toggleTerms(true);
  }

  return {
    terms,
    termsVisible,
    openTerms,
  }
}

export const useExternalRegister = () => {
  const {
    loginKakao
  } = useKaKaoLogin()
  const router = useRouter()
  const {
    loginGoogle
  } = useGoogleLogin()
  const termsCheck = reactive({
    mooveTerms: false,
    indvTerms: false,
    marketingTerms: false,
  })
  const schema = Yup.object().shape({
    mooveTerms: Yup.boolean().isTrue(),
    indvTerms: Yup.boolean().isTrue(),
  });

  const register = async (platform: string, terms?: any) => {
    switch (platform) {
      case 'google':
        await loginGoogle()
        break
      case 'kakao':
        await loginKakao()
        break
      case 'common':
        //ter,s cjecl 를 참으로 하는 api
        await modifyTermsCheckApi({ allowAd: terms.marketingTerms })
        router.push({ name: 'RegisterSuccessView' })
    }
  }

  return {
    termsCheck,
    schema,
    register,
  }
}


export const useRegisterConfig = () => {
  const {t} = useI18n()
  const formRef = ref()
  const form = reactive({
    phone: '',
  })
  const schema = Yup.object().shape({
    phone: Yup.string()
      .required('valid.required')
      .matches(RegExp('^[0-9]'), 'valid.phone')
      .max(PHONE_LENGTH, (msg) => t('valid.maxLength', { max: msg.max })),
  });
  const register = async () => {
    await RegisterPhoneApi( {
      phone: form.phone,
    });
  }

  return {
    formRef,
    form,
    schema,
    register,
  }
}
