
import {defineComponent} from "vue";
import {RouteLocationNamedRaw, useRoute, useRouter} from 'vue-router';
import { goBack } from '@/script/common/routeScript';

export default defineComponent({
  name: "CommonHeader.vue",
  props: {
    data: {
      default: {
        name: '',
        icon: '',
      }
    },
    back: {
      default: '',
    }
  },
  setup(props, context) {

    return {
      goBack
    }
  }
})
