import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "home-layout-header-row"
}
const _hoisted_2 = {
  key: 1,
  class: "filter-section"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_event_app_layout_header = _resolveComponent("event-app-layout-header")!
  const _component_cltn_type_filter_comp = _resolveComponent("cltn-type-filter-comp")!
  const _component_event_sort_filter_comp = _resolveComponent("event-sort-filter-comp")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CommonFooter = _resolveComponent("CommonFooter")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["home-layout discover-layout", _ctx.modeClass])
  }, [
    (!_ctx.$route.meta.noHeader)
      ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
          _createVNode(_component_event_app_layout_header)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.$route.meta.noHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_cltn_type_filter_comp, { class: "type-filter" }),
          _createVNode(_component_event_sort_filter_comp)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", null, [
      _createVNode(_component_router_view),
      _createVNode(_component_CommonFooter)
    ])
  ], 2))
}