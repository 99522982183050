
import {defineComponent, onMounted, ref, watch} from 'vue';
import SearchSvg from '@/components/svg/SearchSvg.vue';
import {useHomeListConfig, useHomeListModeConfig} from '@/script/homeScript';
import {store} from '@/store';
import {useRoute, useRouter} from 'vue-router';
import {HomeViewType} from '@/models/types';

export default defineComponent({
  name: 'HomeLayoutHeader',
  components: {
    SearchSvg,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const titleRef = ref()
    const {
      listMode,
      changeMode,
    } = useHomeListModeConfig()

    const {
      viewMode,
      toggleViewMode,
    } = useHomeListConfig()
    const tabClass = (mode: HomeViewType, type: HomeViewType) => {
      return mode === type ? 'selected' : ''
    }

    const routeSearch = () => {
      router.push({name: 'DiscoverSearchView'})
    }

    return {
      titleRef,
      listMode,
      viewMode,
      changeMode,
      routeSearch,
      toggleViewMode,
      tabClass,
    }
  }
})
