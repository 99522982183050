import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mv-ntfc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textList, (line, index) => {
      return (_openBlock(), _createElementBlock("p", {
        key: index,
        class: _normalizeClass(_ctx.className)
      }, _toDisplayString(line), 3))
    }), 128))
  ]))
}