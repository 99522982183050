
import { defineComponent } from "vue";
import NftAppSvg from "@/components/svg/NftAppSvg.vue";
import ProfileSvg from "@/components/svg/ProfileSvg.vue";
import SearchSvg from "@/components/svg/SearchSvg.vue";
import TicketSvg from "@/components/svg/CouponSvg.vue";
import { useHomeListConfig } from "@/script/homeScript";
import router from "@/router";
import { useRoute } from "vue-router";
import { store } from "@/store";

export default defineComponent({
  name: "HomeLayoutFooter.vue",
  components: {
    NftAppSvg,
    ProfileSvg,
    SearchSvg,
    TicketSvg,
  },
  setup() {
    const { toggleViewMode, toggleButtonText } = useHomeListConfig();

    const route = useRoute();

    const routeInfo = {
      nft: {
        routeName: ["HomeView", "BrandCltnListView"],
      },
      search: {
        routeName: ["DiscoverView"],
      },
      coupon: {
        routeName: ["CouponListView"],
      },
      profile: {
        routeName: ["MyProfileView"],
      },
    };

    type TabName = keyof typeof routeInfo;

    const toggleTab = (tabName: TabName) => {
      router.push({ name: routeInfo[tabName].routeName[0] });
    };

    const selectClass = (routeName: string, tabName: TabName) => {
      if (routeInfo[tabName].routeName.includes(routeName))
        return "tab-selected";
    };
    const initSearch = () => {
      if (route.name === "SearchView") {
        return;
      } else {
        store.commit("search/INIT_SEARCH", null);
      }
    };

    return {
      toggleViewMode,
      toggleButtonText,
      toggleTab,
      selectClass,
      initSearch,
    };
  },
});
