import { string } from 'yup';
import * as Yup from 'yup';

export const getFieldSchema = (field: string) => {
  switch (field) {
    case 'email':
      return string().required('valid.required').email('valid.email')
    case 'pwd':
      return string().required('valid.required')
    case 'phone':
      return string().required('valid.required').matches(RegExp('^[0-9]'), 'valid.phone')
    case 'pwdConfirm':
      return Yup.string()
        .required('valid.required')
        .oneOf([Yup.ref('pwd')], 'valid.pwdConfirm')
    default:  return string()
  }
}

export const scrollToField = (fieldName: string) => {
  const element = document.getElementById(fieldName)
  if (element) {
    window.scroll({
      top: window.scrollY + element.getBoundingClientRect().top - 400,
      behavior: 'smooth',
    })
  }
}
