
import {computed, defineComponent} from 'vue';
import FilterSvgGroup from '@/components/svg/FilterSvgGroup.vue';

export default defineComponent({
  name: 'MvTab',
  components: {
    FilterSvgGroup,
  },
  props: {
    content: {
      default: {
        icon: '',
        label: '',
        value: '',
      }
    },
    selected: {
      default: false
    },
    type: {
      default: 'round'
    }
  },
  setup(props) {
    const className = computed(() => {
      let className = ['mv-tab-row']
      if (props.type) className.push(`mv-tab-type-${props.type}`)
      if (props.selected) className.push('mv-tab-selected')
      return className.join(' ')
    })

    return {
      className,
    }
  }
})
