import {store} from '@/store';
import {NtfcOptions} from '@/models/popupModels';

export const showNtfc = (text: string, options?: NtfcOptions) => {
  store.commit('popup/DISPLAY_NTFC', { text, options })
}

export const showAlert = (text: string, options?: NtfcOptions) => {
  store.commit('popup/DISPLAY_ALERT', { text, options })
}
