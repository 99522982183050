import axios from 'axios';
import router from "@/router";
import {getCustomError} from '@/config/errors';
import {showNtfc} from '@/script/common/popupScript';
import {store} from '@/store';
const authExclude = ['/notifications/unconfirm']
const instance = axios.create({
    baseURL: '/api/v1',
})

// instance.interceptors.response.use((response) => {
//     if(response.data.error) return errorHandler(response);
// })

// const errorHandler = (response) => {
// }
instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
      if (error.response.status === 401) {
        if (authExclude.includes(error.response.config.url)) throw error
        store.commit('auth/INIT_AUTH')
        router.push({ name: 'StartView', query: { code: '401' } })
        return { data: '' }
      }
      else if (error.response.data) {
        const customError = getCustomError(error.response.data.errorCode, error.response.data.data)
        if (customError) {
          throw customError
        } else {
          showNtfc(error.response.data.message, { i18n: false, isError: true })
          throw error
        }
      }
    }
);


export default instance
