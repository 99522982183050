<template>
  <div
      v-if="visible"
      class="mv-modal"
  >
    <div class="modal-back">
      <div class="modal-content" @click.stop="() => {}">
        <div class="modal-header" v-if="isHeader"></div>
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: 'MvModal',
  emits: ['close'],
  props: {
    isHeader: {
      default: true,
    },
    visible: {
      default: false,
    },
  },
  setup(props, context) {
    return {
    }
  }
})
</script>

<style scoped>

</style>