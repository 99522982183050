import { ref } from 'vue';
import { checkNtfcApi, getUncheckedNtfcApi, getUncheckedNtfcListApi } from '@/api/ntfcApi';
import { NtfcResponse } from '@/models/response/ntfcResponseModel';

export const useNftNtfc = () => {
  const info = ref<NtfcResponse>({
    id: 0,
    type: '',
  });
  const list = ref<number[]>([]);

  const getNtfcList = async () => {
    const { list: ntfList } = await getUncheckedNtfcListApi();
    list.value = ntfList;
  };

  const getNtfc = async (id: number) => {
    info.value = await getUncheckedNtfcApi(id);
  };

  const checkNtfc = async (ntfcId: string) => {
    await checkNtfcApi(ntfcId);
  };

  return { info, list, getNtfcList, getNtfc, checkNtfc };
};
