
import {defineComponent} from 'vue';
import MvTab from '@/components/common/tab/MvTab.vue';
import {useDiscoverListTypeConfig} from '@/script/discover/discoverScript';
import {NftCtgy} from '@/models/types';

export default defineComponent({
  name: 'NftTypeFilterComp',
  components: {
    MvTab,
  },
  setup() {
    const {
      changeType,
      cltnType,
    } = useDiscoverListTypeConfig()
    const filterList = [
      {
        label: 'nftType.all',
        value: ''
      },
      {
        label: 'nftType.TICKET',
        value: 'TICKET',
      },
      {
        label: 'nftType.MEMBERSHIP',
        value: 'MEMBERSHIP',
      },
    ]

    return {
      filterList,
      cltnType,
      changeType,
    }
  }
})
