
import MooveTerms from '@/components/pages/register/terms/MooveTerms.vue';
import IndvTerms from '@/components/pages/register/terms/IndvTerms.vue';
import AgreeModalComp from '@/components/common/modal/AgreeModalComp.vue';
import {useTermsAgree} from '@/script/auth/reigsterScript';
import {defineComponent} from 'vue';

export default defineComponent({
  components: {
    MooveTerms,
    IndvTerms,
    AgreeModalComp,
  },
  setup() {
    const {
      terms,
      termsVisible,
      openTerms,
    } = useTermsAgree()

    return {
      terms,
      termsVisible,
      openTerms,
    }
  }
})
