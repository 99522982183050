
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    fill: {
      default: "currentColor",
    },
    viewBox: {
      default: "0 0 28 28",
    },
    disabled: {
      default: false,
    },
  },
});
