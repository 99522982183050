import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M11.2857 2C10.1022 2 9.14286 2.95939 9.14286 4.14286V6.28571H4.14286C2.95939 6.28571 2 7.2451 2 8.42857V19.8571C2 21.0406 2.95939 22 4.14286 22H12.7143C13.8978 22 14.8571 21.0406 14.8571 19.8571V17.7143H19.8571C21.0406 17.7143 22 16.7549 22 15.5714V4.14286C22 2.95939 21.0406 2 19.8571 2H11.2857ZM13.4286 17.7143H11.2857C10.1022 17.7143 9.14286 16.7549 9.14286 15.5714V7.71429H4.14286C3.74837 7.71429 3.42857 8.03408 3.42857 8.42857V19.8571C3.42857 20.2516 3.74837 20.5714 4.14286 20.5714H12.7143C13.1088 20.5714 13.4286 20.2516 13.4286 19.8571V17.7143ZM10.5714 4.14286C10.5714 3.74837 10.8912 3.42857 11.2857 3.42857H19.8571C20.2516 3.42857 20.5714 3.74837 20.5714 4.14286V15.5714C20.5714 15.9659 20.2516 16.2857 19.8571 16.2857H11.2857C10.8912 16.2857 10.5714 15.9659 10.5714 15.5714V4.14286Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M12 7C12 6.60551 12.3198 6.28571 12.7143 6.28571H18.4286C18.8231 6.28571 19.1429 6.60551 19.1429 7C19.1429 7.39449 18.8231 7.71429 18.4286 7.71429H12.7143C12.3198 7.71429 12 7.39449 12 7Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M12 9.85714C12 9.46265 12.3198 9.14286 12.7143 9.14286H15.5714C15.9659 9.14286 16.2857 9.46265 16.2857 9.85714C16.2857 10.2516 15.9659 10.5714 15.5714 10.5714H12.7143C12.3198 10.5714 12 10.2516 12 9.85714Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_4)
  ]))
}