import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ntfc-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mv_ntfc = _resolveComponent("mv-ntfc")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ntfcList, (ntfc, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "ntfc-item"
      }, [
        (ntfc.text)
          ? (_openBlock(), _createBlock(_component_mv_ntfc, {
              key: 0,
              text: ntfc.text,
              options: ntfc.options
            }, null, 8, ["text", "options"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}