
import {defineComponent} from 'vue';
import MvButton from '@/components/common/button/MvButton.vue';
import MvModal from '@/components/common/modal/MvModal.vue';

export default defineComponent({
  name: 'AgreeModalComp',
  emits: ['disagree', 'agree', 'update:visible', 'confirm'],
  props: {
    visible: {
      default: false,
    },
    mode: {
      default: 'agree'
    }
  },
  components: {
    MvButton,
    MvModal,
  },
  setup(props, context) {
    const actionDisagree = () => {
      context.emit('update:visible', false)
      context.emit('disagree')
    }

    const actionAgree = () => {
      context.emit('update:visible', false)
      context.emit('agree')
    }

    const actionConfirm = () => {
      context.emit('update:visible', false)
      context.emit('confirm')
    }

    return {
      actionDisagree,
      actionAgree,
      actionConfirm,
    }
  }
})
