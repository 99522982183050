import { ref } from 'vue';

export const useModal = () => {
  const isOpen = ref(false);
  const toggleModal = (value: boolean) => {
    if (value) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    isOpen.value = value;
  };
  return {
    toggleModal,
    isOpen,
  };
};

