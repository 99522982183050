import { isNaN } from "lodash";
import {CURRENCY_TYPE, CurrencyType} from '@/models/types';
import { VueI18nTranslation } from 'vue-i18n'

export const ellipsisString = (text: string, limit: number, startNumber: number, endNumber: number, unknown?: string) => {
    if (!text) return unknown? unknown : "-";
    if (text.length > limit) {
        return text.substring(0, startNumber) + "..." + text.substring(text.length - 1 - endNumber)
    }
    return text
}

export const getWalletAddressEnd4 = (address: string ) => {
    if (address.length >= 4) return address.substring(address.length - 4, address.length)
    return address
}

export const ellipsisWalletAddressMiddle = (address: string ) => {
    if (address) {
        return address.substring(0, 6) +  '...' + address.substring(address.length - 4, address.length)
    }
    return ''
}

export const displayRational = (numString: string) => {
    if (isNaN(Number(numString))) return NaN;
    if (numString === "") return "";
    if (numString.includes(".")) {
        const numArray = numString.split(".");
        numArray[0] = Number(numArray[0]).toLocaleString();
        if (numArray.length > 1 ) return numArray.join(".");
        else return numArray[0] + "."
    }
    return Number(numString).toLocaleString();
}

export const avatarText = (value: string) => {
    if (!value || typeof value !== 'string')
        return ''
    return value.toUpperCase().charAt(0)
}

export const isFinishSupportKorean = (letter: string) => {
  const charCode = letter.charCodeAt(0);
  const supportCode = (charCode - 44032) % 28;
  return supportCode !== 0;
}

export const ellipsisAddress = (address: string) => {
  if (address) {
    const length = address.length
    return  `${address.substring(0, 5)}...${address.substring(length - 4, length)}`
  }
  return ''
}

export const getLocationName = (location: string) => {
  if (location) return location.split(',')[0]
  return ''
}

export const parseLocation = (locString: string) => {
  let place = ''
  let address = ''
  if (locString) {
    const [placeString, ...rest] = locString.split(',')
    place = placeString
    address = rest ? rest.join(',') : placeString
  }
  return {
    place,
    address
  }
}

export const getPriceString = (price: number, priceUnit: CurrencyType, t: VueI18nTranslation) => {
  if (CURRENCY_TYPE[priceUnit]) return t(CURRENCY_TYPE[priceUnit].i18nUnit, [price?.toLocaleString()])
  else return price?.toLocaleString()
}

export const parseImageText = (text: string) => {
  return text.split(';;')
}
// eslint-disable-next-line
export const getOrderName = (eventName: string, count: number, t: VueI18nTranslation) => {
  if (count > 1) return `${eventName} ${count}매`
  return eventName
  // if (count > 1) return `${eventName} ${t('payDetail.sheets', { count })}`
  // return eventName
}
