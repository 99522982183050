
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "MvNtfc.vue",
  props: {
    text: {
      default: "",
    },
    options: {
      default: {},
    },
  },
  setup(props) {
    const { t } = useI18n();
    const options = computed(() => {
      return {
        i18n: true,
        isError: false,
        ...props.options,
      };
    });
    const splitNtfc = (text: string) => {
      return text.split("\n");
    };
    const textList = computed(() => {
      if (!options.value.i18n) return splitNtfc(props.text);
      else return splitNtfc(t(props.text));
    });

    const className = computed(() => {
      let className = "";
      if (options.value.isError) className += " error-message";
      return className;
    });

    return {
      textList,
      className,
    };
  },
});
