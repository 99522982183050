import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/search",
    name: "SearchView",
    component: () => import("@/components/pages/search/SearchView.vue"),
    meta: {
      layout: "search",
      blankTheme: 4,
    },
  },
];

export default routes;
