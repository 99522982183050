import {computed} from 'vue';
import {store} from '@/store';
import {chagneTheme} from '../flutter/flutterBridgeScript';

export const useCouponListModeConfig = () => {
  const listMode = computed(() => {
    return store.state.status.couponList.mode === 'able';
  });
  const changeMode = (value: boolean) => {
    chagneTheme(value ? {theme: 'white'} : {theme: 'dark'});
    if (value) store.state.status.couponList.mode = 'able';
    else store.state.status.couponList.mode = 'disabled';
  };
  const className = computed(() => {
    return store.getters['status/getCouponListClass'];
  });
  return {
    listMode,
    changeMode,
    className,
  };
};
