import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("rect", {
      x: "2.75",
      y: "4.75",
      width: "18.5",
      height: "14.5",
      rx: "2.25",
      stroke: _ctx.fill,
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M8.5 12.5C9.03043 12.5 9.53914 12.2893 9.91421 11.9142C10.2893 11.5391 10.5 11.0304 10.5 10.5C10.5 9.96957 10.2893 9.46086 9.91421 9.08579C9.53914 8.71071 9.03043 8.5 8.5 8.5C7.96957 8.5 7.46086 8.71071 7.08579 9.08579C6.71071 9.46086 6.5 9.96957 6.5 10.5C6.5 11.0304 6.71071 11.5391 7.08579 11.9142C7.46086 12.2893 7.96957 12.5 8.5 12.5Z",
      stroke: _ctx.fill,
      "stroke-width": "1.5",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M11.5 15.5C11.5 14.7044 11.1839 13.9413 10.6213 13.3787C10.0587 12.8161 9.29565 12.5 8.5 12.5C7.70435 12.5 6.94129 12.8161 6.37868 13.3787C5.81607 13.9413 5.5 14.7044 5.5 15.5M14 10H18M15 14H18",
      stroke: _ctx.fill,
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_4)
  ], 8, _hoisted_1))
}