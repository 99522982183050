import {STORAGE_LIST} from '@/config/constants';
import {requestUrl} from '@/utils/index';
import {CustomMap} from '@/models/commonModels';
import { Router } from 'vue-router'

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  );
};

export const isMetamaskMobile = () => {
  const userAgent = navigator.userAgent;
  return /MetaMAskMobile/i.test(userAgent);
};

export const isKaikasMobile = () => {
  const userAgent = navigator.userAgent;
  return /Kaikas/i.test(userAgent);
};

export const isInAppBrowser = () => {
  const userAgent = navigator.userAgent;
  return userAgent.match(/inapp|kakaotalk|line|naver|snapchat|wirtschaftswoche|thunderbird|instagram|everytimeapp|whatsApp|electron|wadiz|aliapp|zumapp|iphone(.*)whale|android(.*)whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|trill\/[^1]/i)
}

export const getLinkUrl = (addQuery?: CustomMap) => {
  const query: CustomMap = {}
  if (addQuery) {
    Object.entries(addQuery).forEach(([key, value]) => {
      query[key] = value
    })
  }
  STORAGE_LIST.forEach(name => {
    if (sessionStorage.getItem(name)) query[name] = sessionStorage.getItem(name) as string
  })
  return requestUrl(process.env.VUE_APP_BASE_URL as string, { query })
}

export const routeToBasicBrowser = (url: string, router: Router) => {
  const userAgent = navigator.userAgent;
  if (userAgent.match(/kakaotalk/i)) {
    location.href='kakaotalk://web/openExternal?url='+encodeURIComponent(url);
  } else if (userAgent.match(/line/i)) {
    if (url.indexOf('?')!==-1) {
      location.href = url + '&openExternalBrowser=1'
    } else {
      location.href = url + '?openExternalBrowser=1'
    }
  } else if (isInAppBrowser()) {
    if (userAgent.match(/iphone|ipad|ipod/i)) {
      router.push({ name: 'InAppRouteView' })
    } else {
      location.href='intent://'+url.replace(/https?:\/\//i,'')+'#Intent;scheme=http;package=com.android.chrome;end';
    }
  }
}
