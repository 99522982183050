import {store} from '@/store'
import {computed, ref} from 'vue'
import {
  postGenerateQRDataApi,
} from '@/api/nftApis'
import router from '@/router'
import { getQrNftDataApi } from '@/api/cltnApis';

export const useNftDetail = () => {
  const loading = ref(false)
  const fetchNftDetail = async (
    nftId: number
  ) => {
    loading.value = true
    await store.dispatch(
      'detail/fetchNftDetail',
      nftId
    )
    loading.value = false
  }

  const detail = computed(() => {
    return store.state.detail.nft
  })
  const initDetail = () => {
    store.commit('detail/INIT_NFT_DETAIL')
  }
  const isFlipLock = computed(() => {
    return getIsLockByDetail(detail.value)
  })

  return {
    detail,
    loading,
    fetchNftDetail,
    initDetail,
    isFlipLock,
  }
}

export const useLockedNftDetail = () => {
  const loading = ref(false)
  const detail = ref<any>({})
  const fetchNftDetail = async (
    token: string
  ) => {
    try {
      loading.value = true
      detail.value = await getQrNftDataApi(token)
      loading.value = false
    } catch (e) {
      router.replace({name: 'HomeView'})
    }
  }

  return {
    detail,
    loading,
    fetchNftDetail,
  }
}

export const useGenerateQRData = () => {
  const loading = ref(false)
  const qrData = ref<any>({})
  const generateQRData = async (
    nftId: string
  ) => {
    try {
      if (nftId) {
        loading.value = true
        qrData.value =
          await postGenerateQRDataApi(nftId)
        loading.value = false
      }
    } catch (e) {
      console.log(e)
    }
  }

  return {
    qrData,
    loading,
    generateQRData,
  }
}

export const getIsLockByDetail =(nftDetail: any) => {
  return nftDetail.qtnrTemplate?.length && !Object.keys(nftDetail?.qtnrInfo)?.length
}
