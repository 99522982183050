<script lang="ts" setup>
</script>

<template>
  <div class="mv-start-logo">
    <svg width="92" height="50" viewBox="0 0 92 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.8924 31.5656C38.2589 35.1999 35.3322 38.1033 31.6766 39.716C28.021 41.3286 23.8954 41.5364 20.0951 40.2991C16.2948 39.0619 13.0891 36.4672 11.0958 33.0155C9.10259 29.5637 8.46304 25.4993 9.3005 21.6057C10.138 17.7122 12.3931 14.2653 15.6312 11.9296C18.8692 9.59386 22.8609 8.53471 26.8366 8.95636C30.8123 9.37801 34.4905 11.2506 37.162 14.2131C39.8336 17.1755 41.3093 21.0181 41.3046 25C41.296 30.8884 43.3719 36.5914 47.1673 41.1065C50.9627 45.6216 56.2347 48.6597 62.0566 49.6869C67.8785 50.7141 73.8776 49.6645 78.9995 46.7227C84.1213 43.781 88.038 39.1353 90.0611 33.6022C92.0841 28.0692 92.0841 22.003 90.0609 16.4701C88.0377 10.9371 84.1209 6.29149 78.999 3.34982C73.8771 0.408156 67.8779 -0.641263 62.056 0.386042C56.2342 1.41335 50.9622 4.45163 47.1669 8.96679C49.2518 11.8034 50.7632 15.0166 51.617 18.4276C53.2504 14.7935 56.1768 11.8902 59.832 10.2775C63.4873 8.66474 67.6126 8.45669 71.4127 9.69344C75.2128 10.9302 78.4187 13.5242 80.4123 16.9754C82.406 20.4265 83.0463 24.4906 82.2097 28.384C81.3731 32.2774 79.119 35.7246 75.8818 38.061C72.6446 40.3974 68.6536 41.4576 64.678 41.0373C60.7025 40.6169 57.0239 38.7458 54.3515 35.7845C51.679 32.8232 50.2019 28.9816 50.2048 25C50.2134 19.1116 48.1376 13.4086 44.3422 8.89349C40.5468 4.37841 35.2748 1.34025 29.4529 0.313077C23.631 -0.714098 17.6318 0.335454 12.51 3.27724C7.38814 6.21902 3.47145 10.8647 1.4484 16.3977C-0.574659 21.9308 -0.574595 27.9969 1.44859 33.5299C3.47177 39.0629 7.38856 43.7085 12.5105 46.6502C17.6324 49.5918 23.6315 50.6412 29.4534 49.6139C35.2753 48.5866 40.5473 45.5484 44.3426 41.0332C42.2588 38.1934 40.7476 34.9782 39.8924 31.5656Z" fill="#00C4AC"/>
    </svg>
    <div class="start-text">{{ $t('start.start') }}</div>
  </div>
</template>

<style lang="scss" scoped>
.mv-start-logo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
</style>

