import { ref } from "vue";
import {useRoute, useRouter} from "vue-router";

export const useTab = () => {
    const selected = ref("")
    const toggleTab = (tabName: string) => {
        selected.value = tabName;
    }
    const tabClass = (tabName: string) => {
        if (selected.value === tabName) return 'tab-item tab-selected'
        else return 'tab-item'
    }
    return {
        selected,
        toggleTab,
        tabClass,
    }
}

export const useRouteTab = () => {
    const router = useRouter();
    const route = useRoute();
    const selected = ref("")
    const toggleTab = (tabName: string) => {
        router.replace({ name: route.name as string, query: { t: tabName } })
        selected.value = tabName
    }
    const tabClass = {
        select: "tab-item tab-selected",
        default: "tab-item"
    }
    return {
        selected,
        toggleTab,
        tabClass,
    }
}
