import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mv-tab" }
const _hoisted_2 = { class: "tab-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_svg_group = _resolveComponent("filter-svg-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.className)
    }, [
      (_ctx.content.icon)
        ? (_openBlock(), _createBlock(_component_filter_svg_group, {
            key: 0,
            icon: _ctx.content.icon
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.content.label)), 1)
    ], 2)
  ]))
}